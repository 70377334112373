import {PLAYERS__ACTION_MAPPER} from '../reducers/players'

export const addPlayerAction = (player) => {
  return (dispatch) => {
    return dispatch({type: PLAYERS__ACTION_MAPPER.ADD_PLAYER, payload: {player}})
  }
}

export const togglePlayerAction = (player) => {
  return (dispatch) => {
    return dispatch({type: PLAYERS__ACTION_MAPPER.TOGGLE_PLAYER, payload: {player}})
  }
}

export const removePlayerAction = (player) => {
  return (dispatch) => {
    return dispatch({type: PLAYERS__ACTION_MAPPER.REMOVE_PLAYER, payload: {player}})
  }
}

export const setMovePlayerAction = ({player, level, score}) => {
  return (dispatch) => {
    return dispatch({type: PLAYERS__ACTION_MAPPER.SET_MOVE, payload: {player, level, score}})
  }
}

export const clearMovePlayerAction = ({player, level}) => {
  return (dispatch) => {
    return dispatch({type: PLAYERS__ACTION_MAPPER.CLEAR_MOVE, payload: {player, level}})
  }
}