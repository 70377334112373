import React, {useContext} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {DialogContent} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import withPlayers from '../../players/HOC/withPlayers'
import DialogActions from '@material-ui/core/DialogActions'
import withGame from '../HOC/withGame'
import {startGameAction} from '../actions/gameActions'
import {Send} from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import PreviousPlayersAsChip from '../../players/components/PreviousPlayersAsChip'
import {SoundContext} from '../../../layout/sound/contexts/SoundContext'


const StartButton = withGame(({game, close, dispatch}) => {

  const sound = useContext(SoundContext)
  const start = () => {
    sound.click()
    dispatch(startGameAction())
  }
  return (<Button onClick={close ? close : start}>
    <Send style={{marginRight: 20}}/> Commencer
  </Button>)
})


const NewGameSettings = ({players}) => {

  return (<Dialog open={true} fullWidth={true} maxWidth="md">
    <DialogTitle id="responsive-dialog-title">
      Joueurs
    </DialogTitle>
    <DialogContent>
      <PreviousPlayersAsChip />
    </DialogContent>
    <DialogActions>
      <StartButton/>
    </DialogActions>

  </Dialog>)
}

export default withPlayers(NewGameSettings)
