
import store from '../../../config/store'
import {addPlayerAction, removePlayerAction, togglePlayerAction} from '../actions/playersActions'


export const removePlayerHandler = (player) => {
  store.dispatch(removePlayerAction(player))
}

export const addPlayerHandler = (player) => {
  store.dispatch(addPlayerAction({
    id: player.id,
    name: player.name,
    image: player.image
  }))
}

export const togglePlayerHandler = (player) => {
  store.dispatch(togglePlayerAction({
    id: player.id,
    name: player.name,
    image: player.image
  }))
}

