import React, { useContext, useRef } from "react"
import DiceGroup from "./DiceGroup"
import "./scene.scss"
import useRound from "../game/hooks/useRound"
import DragToRollTheDice from "./DragToRollTheDice"
//import { IconButton } from "@material-ui/core"
import DiceBoardContext from "./DiceBoardContext"
//import { Close, Refresh } from "@material-ui/icons"

import PressToPlay from "./PressToPlay"

const DICE_FALLING_TIME = 700
const DICE_LOCK_ANIMATION_TIME = 50
const DICE_SIZE = 50

const setPosition = ({ x, y, z = 0 }, base = DICE_SIZE) => {
  const tx = x //- (base / 2)
  const ty = y //- (base / 2)
  const tz = z + base / 2
  return {
    x: tx,
    y: ty,
    z: tz,
    transform: `translateX(${tx}px) translateY(${ty}px) translateZ(${tz}px)`,
  }
}

const GAME_STATE_LANG = {
  onGoing: "En cours",
  idle: "Prêt !",
  ended: "Fini !",
}

const NUMBER_OF_DICES = 5
const NUMBER_OF_THROWS = 3

// const ToggleButton = ({ reset }) => {
//   const context = useContext(DiceBoardContext)
//   return (
//     <div className="yms-board-toggle">
//       <IconButton onClick={reset}>
//         <Refresh />
//       </IconButton>
//       <IconButton onClick={context.toggle}>
//         <Close />
//       </IconButton>
//
//       {/*<Button variant={"contained"} onClick={context.toggle}>*/}
//       {/*  Piste*/}
//       {/*</Button>*/}
//     </div>
//   )
// }

const DiceBoardToggleControls = ({ children }) => {
  const context = useContext(DiceBoardContext)
  const boardAnimationClassName = context.open
    ? "board-show-up"
    : "board-hide-up"
  const boardClassName = `yms-scene ${boardAnimationClassName}`

  const containerClassName = `yms-canvas ${context.open ? "" : "hidden"}`
  return (
    <div className={containerClassName}>
      <div className={boardClassName}>{children}</div>
    </div>
  )
}

const DiceBoard = () => {
  const mode = "press"
  const { state: gameState, rolls, play, rollNumber, reset } = useRound({
    maxRollCount: NUMBER_OF_THROWS,
    diceCount: NUMBER_OF_DICES,
  })

  const diceRolledRef = useRef(false)
  const dices = rolls.map((dice, index) => {
    const start = setPosition({ x: 140 * index + 75, y: 150, z: 200 })
    const end = setPosition({ x: 140 * index + 75, y: 40, z: 0 })
    return {
      ...dice,
      size: DICE_SIZE,
      start,
      end,
    }
  })

  const onRollProgress = (progress) => {
    if (
      gameState !== "ended" &&
      progress > 80 &&
      diceRolledRef.current === false
    ) {
      diceRolledRef.current = true
      play()
    }
  }
  const initRoll = () => (diceRolledRef.current = false)

  return (
    <DiceBoardToggleControls>
      {mode === "drag" ? (
        <DragToRollTheDice
          onStart={initRoll}
          onEnd={initRoll}
          onProgress={onRollProgress}
        />
      ) : (
        <PressToPlay
          rollNumber={rollNumber}
          label={GAME_STATE_LANG[gameState]}
          play={play}
          reset={reset}
        />
      )}

      {/*<div className="yms-game-state">*/}
      {/*  {GAME_STATE_LANG[gameState]}*/}
      {/*  {rollNumber > 0 ? `, Lancé ${rollNumber}` : ""}*/}
      {/*</div>*/}
      {/*<ToggleButton reset={reset} />*/}
      <div className="yms-dice-board" />
      <DiceGroup
        rollNumber={rollNumber}
        dices={dices}
        fallingTime={DICE_FALLING_TIME}
        diceLockAnimationTime={DICE_LOCK_ANIMATION_TIME}
      />
    </DiceBoardToggleControls>
  )
}

export default DiceBoard
