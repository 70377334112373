import React from 'react'
import withGame from '../HOC/withGame'
import useToggle from '../../../hooks/useTogglable'
import withPlayers from '../../players/HOC/withPlayers'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import PodiumComponent from '../../leader-board/components/PodiumComponents'
import {getTotals} from '../utils/utils'

const styles = {
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

function compare(a, b) {
    const da = a.total;
    const db = b.total;
    if (da < db) return -1;
    if (da > db) return 1;
    return 0;
}

const EndGameComponent = ({game, players}) => {

    const [open, toggle] = useToggle(true)

    const items = getTotals(players).sort(compare).reverse()
    if (game.status !== 'ended' || items.length < 1) return null;

    console.log(items)

    return (<Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={() => toggle(false)}
    >
        <h1 style={{paddingTop: 40, animationDelay: '300ms'}} className="animated fadeInUpShort text-center">Félicitations {items[0].name}!</h1>
        <div className="animated fadeInUpShort" style={{...styles.center, padding: '60px 0 40px 0', animationDelay: '600ms'}}>

            <PodiumComponent style={{height: '100%', width: '80%'}} players={items}/>
        </div>

        <div style={styles.center}>
            <Button
              label="Fermer"
              keyboardFocused={true}
              onClick={() => toggle(false)}
            />
        </div>
    </Dialog>)
}


export default withPlayers(withGame(EndGameComponent))