import {GAME_ACTION_MAPPER} from '../reducers/game'
import {PLAYERS__ACTION_MAPPER} from '../../players/reducers/players'
import {LEVELS_ACTION_MAPPER} from '../../levels/reducers/levels'

export const newGameAction = () => {
  return (dispatch) => {
    dispatch({type: PLAYERS__ACTION_MAPPER.NEW_GAME})
    dispatch({type: GAME_ACTION_MAPPER.NEW_GAME})
    dispatch({type: LEVELS_ACTION_MAPPER.NEW_GAME})
  }
}

export const startGameAction = () => {
  return (dispatch) => {
    dispatch({type: GAME_ACTION_MAPPER.START})
  }
}

export const setPickingAction = () => {
  return (dispatch) => {
    dispatch({type: GAME_ACTION_MAPPER.PICKING})
  }
}

export const endGameAction = () => {
  return (dispatch) => {
    dispatch({type: GAME_ACTION_MAPPER.END})
  }
}