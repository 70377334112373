import EncodedStorage from '../../../utils/EncodedStorage'
import {getInitialState} from '../../reducers-tools/reducers-tools'


const STATE_STORAGE_KEY = '@yamsy/SETTINGS'
const storage = new EncodedStorage({key: STATE_STORAGE_KEY})
const defaultState = getInitialState(storage, {
  orientation: 'manual',//auto
  layout: 'horizontal', //vertical
  mode: 'dices', //or dices,
  playersModal: false,
  route: 'board',// or stats
  theme: 'default'
})
const saveState = (state) => {
  storage.set(JSON.stringify(state))
  return state
}


const changeOrientation = (state, {orientation}) => {
  return {
    ...state,
    orientation: orientation
  }
}
const changeLayout = (state, {layout}) => {
  return {
    ...state,
    layout: layout
  }
}
const changeToDiceMode = (state, {mode}) => {
  return {
    ...state,
    mode: mode
  }
}

const togglePlayerModal = (state, {modal}) => {
  return {
    ...state,
    playersModal: modal
  }
}

const toggleTheme = (state, {theme}) => {
  return {
    ...state,
    theme: theme
  }
}
const changeRoute = (state, {route}) => {
  return {
    ...state,
    route: route
  }
}


function settings(state = defaultState, action) {

  switch (action.type) {

    case 'SETTINGS_CHANGE_ORIENTATION': {
      return saveState(changeOrientation(state, action.payload))
    }
    case 'SETTINGS_CHANGE_LAYOUT': {
      return saveState(changeLayout(state, action.payload))
    }
    case 'SETTINGS_TOGGLE_DICE_MODE': {
      return saveState(changeToDiceMode(state, action.payload))
    }
    case 'SETTINGS_TOGGLE_PLAYER_MODAL': {
      return saveState(togglePlayerModal(state, action.payload))
    }
    case 'SETTINGS_TOGGLE_THEME': {
      return saveState(toggleTheme(state, action.payload))
    }
    case 'SETTINGS_CHANGE_ROUTE': {
      return saveState(changeRoute(state, action.payload))
    }
    default: {
      return state
    }
  }
}

export default settings