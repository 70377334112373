import React from "react";
import Header from "../header/Header";
//import SideBarComponent from '../sidebar/SideBarComponent'
import "./application.scss";
import useToggle from "../../hooks/useTogglable";
import FullscreenContext from "../../components/fullscreen/FullscreenContext";
import {
  closeFullScreen,
  openFullScreen,
} from "../../components/fullscreen/fullscreen";
import { ToasterComponent } from "re-toaster";
import theme from "../theme/toaster";
import { SoundContext } from "../sound/contexts/SoundContext";
import useSound from "use-sound";
import diceThrownOnGameBoard from "../../assets/sounds/dice_throw_on_game_board.mp3";
import errorSound from "../../assets/sounds/error.mp3";
import eraseSound from "../../assets/sounds/erase.mp3";
import clickSound from "../../assets/sounds/click.mp3";
import unclickSound from "../../assets/sounds/unclick.mp3";
import Loader from "../loader/Loader";

export const ApplicationLayout = ({ location, children }) => {
  const [open, toggle] = useToggle(false);

  const [playSound, { duration: soundDuration1 }] = useSound(
    diceThrownOnGameBoard
  );
  const [playErrorSound, { duration: soundDuration2 }] = useSound(errorSound);
  const [playEraseSound, { duration: soundDuration3 }] = useSound(eraseSound);
  const [playClick, { duration: soundDuration4 }] = useSound(clickSound);
  const [playUnclick, { duration: soundDuration5 }] = useSound(unclickSound);

  const soundsLoaded =
    [
      soundDuration1,
      soundDuration2,
      soundDuration3,
      soundDuration4,
      soundDuration5,
    ].filter((p) => p !== null).length === 5;

  return (
    <FullscreenContext.Provider
      value={{
        status: open,
        toggle: () => {
          if (open) {
            closeFullScreen();
            return toggle(false);
          }
          openFullScreen();
          toggle(true);
        },
      }}
    >
      <SoundContext.Provider
        value={{
          error: playErrorSound,
          played: playSound,
          erased: playEraseSound,
          click: playClick,
          unclick: playUnclick,
        }}
      >
        <div className="App">
          <div className="App-shell">
            <div id="header">
              <Header />
            </div>

            {soundsLoaded ? children : <Loader />}
          </div>
        </div>
        <ToasterComponent theme={theme} />
      </SoundContext.Provider>
    </FullscreenContext.Provider>
  );
};
export default ApplicationLayout;
