const defaultLevels = {
  levels: [
    {
      id: 'nums-1',
      type: 'num',
      config: {key:1, name:1, title: 'Les As'}
    },
    {
      id: 'nums-2',
      type: 'num',
      config: {key:2, name:2, title: 'Les Deux'}
    },
    {
      id: 'nums-3',
      type: 'num',
      config: {key:3, name:3, title: 'Les Trois'}
    },
    {
      id: 'nums-4',
      type: 'num',
      config: {key:4, name:4, title: 'Les Quatres'}
    },
    {
      id: 'nums-5',
      type: 'num',
      config: {key:5, name:5, title: 'Les Cinqs'}
    },
    {
      id: 'nums-6',
      type: 'num',
      config: {key:6, name:6, title: 'Les Six'}
    },
    {
      id: 'total-a',
      type: 'total',
      config: {name: 'Total A'}
    },
    {
      id: 'bonus-a',
      type: 'total',
      config: {name: 'Bonus', value: 35}
    },
    {
      id: 'total-ab',
      type: 'total',
      config: {name: 'Total', bonus: 35, minForBonus: 60}
    },
    {
      id: 'ofakind-3',
      type: 'ofakind',
      config: { leverage:3, bonus: 5, name: 'Brelan'}
    },
    {
      id: 'custom-s',
      type: 'custom',
      config: { name: 'Petit', bonus: 0}
    },
    {
      id: 'custom-m',
      type: 'custom',
      config: { name: 'Moyen', bonus: 0}
    },
    {
      id: 'custom-l',
      type: 'custom',
      config: { name: 'Grand', bonus: 0}
    },
    {
      id: 'flush-s',
      type: 'flush',
      config: { value: 30, bonus: 30, name: 'Petite Suite'}
    },
    {
      id: 'flush-l',
      type: 'flush',
      config: { value: 40, bonus:40, name: 'Grande Suite'}
    },
    {
      id: 'fullhouse',
      type: 'custom',
      config: { name: 'Full', bonus: 30}
    },
    {
      id: 'ofakind-4',
      type: 'ofakind',
      config: { leverage:4, bonus: 40, name: 'Carré'}
    },
    {
      id: 'ofakind-5',
      type: 'ofakind',
      config: { leverage:5, bonus: 50, name: 'Yams'}
    },
    {
      id: 'total-b',
      type: 'total',
      config: {name: 'Total B'}
    },
    {
      id: 'total',
      type: 'total',
      config: {name: 'Total'}
    },
  ]
}

export default defaultLevels