
class EncodedStorage {
  constructor(options) {
    this.key = options.key
  }

  encode(str) {
    if (typeof str !== 'string') throw new Error('String expected')
    try {
      return btoa(encodeURIComponent(str))
    } catch (e) {
      console.log('Cant encode string')
      console.log(e)
      return str
    }
  }

  decode(str) {
    try {
      return decodeURIComponent(atob(str))
    } catch (e) {
      console.log('BAD DATA FORMAT !')
      console.log(e)
      return str
    }
  }

  get() {
    const content = localStorage.getItem(this.key)
    return content ? this.decode(content) : null
  }

  set(value) {
    const content = this.encode(value)
    localStorage.setItem(this.key, content)
  }

  destroy() {
    localStorage.removeItem(this.key)
  }
}
export default EncodedStorage