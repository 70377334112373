import React from 'react'
import Yamsy from '../assets/images/yamsy.png'
import Button from '@material-ui/core/Button'
import navigate from '../config/navigate'

const styles = {
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 'auto'
  },
  image: {
    cursor: 'pointer',
    margin: 40,
    maxWidth: '100%'
  },
  title: {
    letterSpacing: -1,
    fontWeight: 700,
    textShadow: '1px 2px 3px rgba(0,0,0,.7)'
  },
  subtitle: {
  textShadow: '1px 2px 1px rgba(0,0,0,.7)'}
}

const Home = () => {
  const onStartHandler = () => navigate('/game')
  return (
    <div className="animated fadeIn">
      <div style={{...styles.center, maxWidth: 250}} onClick={onStartHandler}>
        <img style={styles.image} alt={'Yamsy'} src={Yamsy}/>
      </div>
      <div className="animated fadeInUpShort" style={{...styles.center, flexDirection: 'column', animationDelay: '100ms'}}>
        <h1 style={styles.title}>Yamsy</h1>
        <h3 style={styles.subtitle}>Yatzee, Yams score board</h3>
      </div>
      <div  className="animated fadeInUpShort" style={{...styles.center, margin: 20, animationDelay: '200ms'}}>
        <Button size={'large'} onClick={onStartHandler} color={'primary'} variant={'contained'}>Start</Button>
        {/*<Button size={'large'} onClick={() => navigate('/dices')} color={'secondary'} variant={'contained'}>Dices</Button>*/}
      </div>
    </div>

  )
}


export default Home
