import React from 'react'
import {Button} from "@material-ui/core";
import {Column, Row} from "../layout/grid";
import useRound, {IDice} from "../components/game/hooks/useRound";

interface IDiceButtonProps {
    dice: IDice
}

const DiceButton = ({dice}: IDiceButtonProps) => {
  console.log(dice)
    return <Button
        className="animated fadeInUpShort"
        style={{margin: 2}}
        onClick={dice.toggle}
        color={dice.kept ? 'secondary' : 'primary'}
        variant={'contained'}>
        {dice.value}
    </Button>

}

const Dices = () => {

    const {play, reset, state, rolls, rollNumber} = useRound({})

    return (
        <div style={{padding: 50}}>


            <div style={{padding: 20, margin: 20, border: '1px solid #999', borderRadius: 4}}>
                <strong>{state}</strong>
            </div>

            <div style={{padding: 20, margin: 20, border: '1px solid #999', borderRadius: 4}}>
                <strong>{rollNumber}</strong>
            </div>

            <div style={{padding: 20, margin: 20, border: '1px solid #999', borderRadius: 4}}>
                {rolls.filter(d => d.kept).map((dice) => (
                    <DiceButton
                        key={dice.id}
                        dice={dice}
                    />
                ))}
            </div>

            <div style={{padding: 20, margin: 20, border: '1px solid #999', borderRadius: 4}}>
                {rolls
                    .filter(dice => !dice.kept)
                    .map((dice) => {
                        return <DiceButton
                            key={dice.id}
                            dice={dice}
                        />
                    })}
            </div>
            <div>
                <Row>
                    <Column size={6} className="text-center">
                        <Button
                            style={{backgroundColor: '#F0F0F0', color: '#333'}}
                            variant="contained" color={"primary"} onClick={reset}>Reset</Button>
                    </Column>
                    <Column size={6} className="text-center">
                        <Button variant="contained" onClick={play}>roll</Button>
                    </Column>
                </Row>
            </div>


        </div>

    )
}


export default Dices
