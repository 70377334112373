const {document} = window



export function isFullScreen(){
  return Boolean(
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement ||
    document.fullscreen ||
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.fullScreenMode
  )
}

export function openFullScreen() {
  const el = document.documentElement
  if (el.requestFullscreen) return el.requestFullscreen()
  if (el.mozRequestFullScreen) return el.mozRequestFullScreen()
  if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen()
  if (el.msRequestFullscreen) return el.msRequestFullscreen()
}

export function closeFullScreen() {
  if (document.exitFullscreen) return document.exitFullscreen()
  if (document.mozCancelFullScreen) return document.mozCancelFullScreen()
  if (document.webkitExitFullscreen) return document.webkitExitFullscreen()
  if (document.msExitFullscreen) return document.msExitFullscreen()
}

function toggleFullScreen() {
  if (!isFullScreen()) {  // current working methods
    openFullScreen()
  } else {
    closeFullScreen()
  }
}

export default toggleFullScreen