import React, { useContext } from "react"
import { FlatDice } from "./FlatDice"
import DiceBoardContext from "./DiceBoardContext"

// const getDifferentRoll = (prevValue) => {
//   const newValue = getRoll()
//   if (newValue !== prevValue) {
//     return newValue
//   }
//   return getDifferentRoll(prevValue)
// }

const CallDiceBoard = () => {
  // const [value, setValue] = useState(1)
  // // useEffect(() => {
  // //   console.log("effect")
  // //   const timeout = setTimeout(() => {
  // //     //setValue(getDifferentRoll)
  // //   }, 1000)
  // //
  // //   return () => {
  // //     clearTimeout(timeout)
  // //   }
  // // })
  const context = useContext(DiceBoardContext)
  const toggleHandler = () => {
    console.log("clic")
    context.toggle()
  }
  return (
    <div className="call-dice-board-wrapper">
      <div
        className="call-dice-board"
        onClick={toggleHandler}
        //onClick={() => onClick?.() || setValue(getRoll())}
      >
        <div className="call-dice-board-dice">
          <FlatDice faceUp={4} />
        </div>
      </div>
    </div>
  )
}

export default CallDiceBoard
