import React from 'react'
import moment from 'moment'
import './podium.scss'
import Player from './Player'
import PlayerListItem from './PlayerListItem'
moment.locale('fr')

const PodiumComponent = ({date, players, style}) => {
  return (
    <div className="podium" style={style}>
      <div className="leaders">
        {players.slice(0, 3).map(player => (
          <Player date={date} key={player.id} player={player}/>
        ))}
      </div>
      <div className="others">
        {players.slice(3, players.length).map(player => (
          <PlayerListItem date={date} key={player.id} player={player}/>
        ))}
      </div>
    </div>
  )
}

export default PodiumComponent