import {applyMiddleware, createStore, compose, combineReducers} from "redux"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import { connectRouter, routerMiddleware } from 'connected-react-router'
import history from "./history"
import device from '../layout/device/reducers/device'
import players from '../components/players/reducers/players'
import settings from '../components/settings/reducers/settings'
import game from '../components/game/reducers/game'
import levels from '../components/levels/reducers/levels'
import playersHistory from '../components/players/reducers/playersHistory'
import playedGamesHistory from '../components/players/reducers/playedGamesHistory'
import { toaster } from 're-toaster'

const middleware = applyMiddleware(promise(), thunk, routerMiddleware(history))
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middleware)



const reducers = {
    router: connectRouter(history),
    toaster,
    device: device,
    players: players,
    playersHistory: playersHistory,
    playedGamesHistory: playedGamesHistory,
    settings: settings,
    game: game,
    levels: levels
}

const store = Object.keys(reducers).length > 0 ? createStore(combineReducers(reducers), enhancer) : createStore(enhancer)

window.store = store

export default store