import React from 'react'
import {TextField, withStyles} from '@material-ui/core'


export class FieldComponent extends React.Component {
    up(e) {
        const {name, onChange, type} = this.props
        if (!name) {
            console.error('please provide name to your input component')
            return;
        }
        if (onChange)
            onChange(name, type === 'checkbox' ? e.target.checked : e.target.value, e)
    }

    onEnterKey(e) {
        if (this.props.onEnterKey && (e.key || e.keyCode) === 'Enter') this.props.onEnterKey()
    }

    component() {
        const {value, type, gotError, name, placeholder, classes} = this.props


        const className = (this.props.className || '') + (gotError ? ' has-error' : '')


        const text = (<TextField type={type}
                                 className={classes.textField}
                                 value={value}
                                 name={name}
                                 onKeyPress={(e) => this.onEnterKey(e)}
                                 onChange={(e, e2, e3) => this.up(e, e2, e3)}
                                 required={this.props.required}
                                 readOnly={this.props.disabled}
                                 onBlur={this.props.onBlur || null}
                                 label={placeholder || name}
        />)

        switch (type) {
            case 'number':
            case 'password':
            case 'text': {
                return text
            }

            case 'textarea': {
                return (<textarea className={className}

                                  value={value}
                                  name={name}
                                  onChange={e => this.up(e)}
                                  onBlur={this.props.onBlur || null}
                                  required={this.props.required}
                                  readOnly={this.props.disabled}
                                  label={placeholder || name}
                />)
            }
            case 'checkbox': {
                return (<input type="checkbox"
                               className={className}
                               name={name}
                               value={!!value}
                               checked={!!value}
                               onChange={e => this.up(e)}
                               required={this.props.required}
                               readOnly={this.props.disabled}
                />)
            }
            default: {
                return text
            }
        }
    }

    render() {
        return this.component()
    }
}

export default withStyles((theme) => ({
    textField: {
        width: '100%'
    }
}))(FieldComponent)
