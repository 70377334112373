import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import './score-dialog.scss'
import Slide from '@material-ui/core/Slide'
const SlideUp = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} timeout={400} />)
const ScoreDialog = ({open, onClose, children, title = '', infoMessage}) => {
  return (
    <Dialog
      TransitionComponent={SlideUp}
      fullWidth={true}
      //maxWidth="md"
      open={open}
      onClose={onClose}
    >
      {infoMessage}
      <div className="score-dialog">
        {!!title && (<div className="score-dialog-title">
          {title}
        </div>)}
        <div className="score-dialog-content" >
          {children}
        </div>

      </div>
    </Dialog>
  )
}


export default ScoreDialog
