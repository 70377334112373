
function compare(a, b) {
  return b.played - a.played
}

export const getNextPlayer = (players) => {
  return players.map((p, idx) => ({
    ...p,
    played: p.scoreBoard.length + (!idx ? 0 : .5)
  })).sort(compare).reverse()[0]
}
