import React from "react"

function Dot() {
  return <div className="dot" />
}
function Column({ children }) {
  return <div className="column">{children}</div>
}

function Face(props) {
  const idx = props.idx
  const list = []
  if (idx === 4) {
    for (let col = 0; col < 2; col++) {
      const children = []
      for (let i = 0; i < 2; i++) children.push(<Dot key={i} />)
      list.push(<Column key={col}>{children}</Column>)
    }
  } else if (idx === 5) {
    for (let col = 0; col < 3; col++) {
      const children = []
      if (col === 1) {
        children.push(<Dot key={1} />)
      } else {
        for (let i = 0; i < 2; i++) children.push(<Dot key={i} />)
      }
      list.push(<Column key={col}>{children}</Column>)
    }
  } else if (idx === 6) {
    for (let col = 0; col < 2; col++) {
      const children = []
      for (let i = 0; i < 3; i++) children.push(<Dot key={i} />)
      list.push(<Column key={col}>{children}</Column>)
    }
  } else {
    for (let i = 0; i < idx; i++) {
      list.push(<Dot key={i} />)
    }
  }

  return <div className={props.className || "side side-" + idx}>{list}</div>
}

export const FlatDice = ({ faceUp, onClick }) => {
  return (
    <div className={"dice up-" + faceUp} onClick={onClick}>
      <Face idx={1} />
      <Face idx={2} />
      <Face idx={3} />
      <Face idx={4} />
      <Face idx={5} />
      <Face idx={6} />
    </div>
  )
}

export const DiceFlatComponent = ({ faceUp, className }) => {
  return (
    <div className={"dice-flat " + (className || "")}>
      <Face className={"face-" + faceUp} idx={faceUp} />
    </div>
  )
}

class DiceWrapper extends React.Component {
  constructor() {
    super()
    this.state = {
      faceUp: 1,
    }
  }
  roll = () => {
    const faceUp = Math.round(Math.random() * 6)
    this.setState({ faceUp })
  }
  render() {
    return (
      <div className="dice-wrapper" onClick={this.roll}>
        <FlatDice faceUp={this.state.faceUp} />
      </div>
    )
  }
}
export default DiceWrapper
