
export function toggleOrientationMode(orientation = 'auto') {
    return function (dispatch) {
        dispatch({type: 'SETTINGS_CHANGE_ORIENTATION', payload: {orientation}})
    }
}
export function toggleLayout(layout = 'horizontal') {
    return function (dispatch) {
        dispatch({type: 'SETTINGS_CHANGE_LAYOUT', payload: {layout}})
    }
}
export function toggleTheme(theme = 'default') {
    return function (dispatch) {
        dispatch({type: 'SETTINGS_TOGGLE_THEME', payload: {theme}})
    }
}
export function toggleDiceMode(mode = 'default') {
    return function (dispatch) {
        dispatch({type: 'SETTINGS_TOGGLE_DICE_MODE', payload: {mode}})
    }
}