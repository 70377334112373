import React, { useContext } from "react"
import { Column, Row } from "../../layout/grid"
import Button from "@material-ui/core/Button"
import DiceBoardContext from "./DiceBoardContext"
import { Close, DoubleArrow, Refresh } from "@material-ui/icons"
import "./pressToPlay.scss"

const styles = {
  left: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

const PressToPlay = ({ className = "", play, reset, label, rollNumber }) => {
  const context = useContext(DiceBoardContext)
  return (
    <div className={`yms-drag-container ${className}`}>
      <Row
        className="no-gutters"
        style={{ height: "100%", padding: "10px 30px" }}
      >
        <Column size={4} style={styles.left}>
          <Button
            disableElevation
            className="button-roll"
            variant={"contained"}
            onClick={play}
          >
            Lancer les dés
            <DoubleArrow
              style={{ marginLeft: 10, color: "rgba(255,255,255,.8)" }}
            />{" "}
            <DoubleArrow style={{ marginLeft: -10 }} />
          </Button>
        </Column>
        <Column size={4} style={styles.center}>
          {rollNumber > 0 && (
            <div className="yms-game-state animated fadeInUp">
              {/*{label}*/}
              {rollNumber > 0 ? `Lancé ${rollNumber}` : ""}
            </div>
          )}
        </Column>
        <Column size={4} style={styles.right}>
          <Button
            className="button-roll secondary"
            variant={"contained"}
            onClick={reset}
            style={{
              marginRight: 10,
            }}
          >
            <Refresh style={{ marginRight: 10 }} /> Rejouer
          </Button>
          <Button
            variant={"contained"}
            onClick={context.toggle}
            className="button-roll secondary"
          >
            <Close style={{ marginRight: 10 }} /> Fermer
          </Button>
        </Column>
      </Row>
    </div>
  )
}

export default PressToPlay
