import React from 'react'
import {Provider} from 'react-redux'
import store from './config/store'
import router from "./config/router";
import withRoot from './withRoot'
import './layout/yamsy/yamsy.scss'

function App() {
  return (
    <Provider store={store}>
      {router}
    </Provider>
  );
}

export default withRoot(App);
