import React from "react"
import Dice from "./Dice"

const DiceGroup = ({ dices = [], fallingTime, diceLockAnimationTime }) => {
  return dices.map((dice) => (
    <Dice
      soundVolume={15}
      key={dice.id}
      score={dice.value}
      fallingTime={fallingTime}
      dice={dice}
      diceLockAnimationTime={diceLockAnimationTime}
    />
  ))
}

export default DiceGroup
