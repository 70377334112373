export const getInitialState = (storage, defaultState) => {
  const data = storage.get()
  if(data) {
    return JSON.parse(data)
  }
  storage.set(JSON.stringify(defaultState))
  return defaultState
}
//
// export const saveState = (storage, state) => {
//   storage.set(JSON.stringify(state))
//   return state
// }