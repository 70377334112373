import React, { useContext, useState} from 'react'
import {LevelRow} from '../../containers/LevelRow'
import {BlankKey, ScoreComponent} from './SharedLevelComponent'
import withPlayers from '../../../players/HOC/withPlayers'
import {ScoreModalContext} from '../../contexts/ScoreModalContext'
import store from '../../../../config/store'
import {setMovePlayerAction} from '../../../players/actions/playersActions'
import checkIfScoreIsPlayable, {getAllPossibleValues, getRolls} from '../../../game/utils/checkIfScoreIsPlayable'
import {popError} from '../../../../layout/toaster/toaster'
import {SoundContext} from '../../../../layout/sound/contexts/SoundContext'

const ScoreKey = ({value, onChange}) =>  {
  return (
    <div className="col-md-4 col-sm-4 col-xs-4 text-center padkey" onClick={() => onChange(value)}>
      {value === -1 ? <i className="fa fa-chevron-left"/> : value}
    </div>
  )
}



function isValid(score, level, player){
  const min = (level.id === 'custom-s' ? score : undefined) || (player.scoreBoard.find( p => p.id === 'custom-s')?.value || undefined) || 0
  const med = (level.id === 'custom-m' ? score : undefined) || (player.scoreBoard.find( p => p.id === 'custom-m')?.value || undefined) || (min + .5 ) || 0
  const max = (level.id === 'custom-l' ? score : undefined) || (player.scoreBoard.find( p => p.id === 'custom-l')?.value || undefined) || (med ?  med + .5 : undefined) || 30

  //console.log(min, med, max)
  return min < med && med < max
}

function isFull ({ rollsCount = 5, dices = [1,2,3,4,5,6], score = 30 }) {
  const rolls = getRolls({rollsCount, dices})
  const v = getAllPossibleValues(rolls, score)
  const uniques = v.reduce((s, t) => {
    const unique = [...new Set(t)]
    const count = t.filter( v => v === t[0]).length
    if(unique.length === 2 && (count === 2 || count === 3)) {
      return s.concat([t])
    }
    return s
  }, [])
  return uniques.length > 0
}


const ScorePad = ({player, level, score, onChange}) => {
  const context = useContext(ScoreModalContext)
  const sound = useContext(SoundContext)
  const [state, setState] = useState({
    value: (score || '') + ''
  })

  const {value} =state

  const triggerScore = (v) => {
    if (v === 'x') {
      if (onChange) onChange(v)
    } else {
      let temp = value
      if (temp && v === -1) {
        const _v = temp.split('')
        _v.pop()
        temp = _v.join('')
      } else if (v >= 0) {
        temp = value + '' + v
      }
      setState({ value: temp })
    }
  }

  //console.log(isValid(score, level, player))

  // console.log(player.scoreBoard)
  // console.log(player.scoreBoard.filter( p => p.id.split('-').shift() === 'custom'))




  const submit = () => {
    const score = (value ? parseInt(value, 10) : 0)



    checkIfScoreIsPlayable({score})
      .then(() => {
        if(level.id === 'fullhouse') {
          if(isFull({rollsCount: 5, score})) {
            return Promise.resolve()
          }
          return Promise.reject()
        }
        return Promise.resolve()
      })
      .then(() => {
        if(level.type === 'custom' && level.id !== 'fullhouse') {
          if(isValid(score, level, player)) {
            return Promise.resolve()
          }
          return Promise.reject()
        }
        return Promise.resolve()
      })
      .then(() => {
        const withBonus = score > 0 ? ( score + level.config?.bonus || 0) : score
        store.dispatch(setMovePlayerAction({player, level, score: withBonus}))
        sound.played()
        context.clearTurn()
      })
      .catch(e => {
        popError('Ce score n\'est pas réalisable...')
        sound.error()
      })
      .finally(() => {

      })

  }

  const keys = []
  for (let i = 1; i < 10; i++) {
    keys.push(<ScoreKey onChange={triggerScore} key={'pad-' + i} value={i}/>)
  }
  keys.push(<ScoreKey onChange={triggerScore} key={'pad-back'} value={-1}/>)
  //keys.push(<BlankKey key={'pad-blank-l'} />)
  keys.push(<ScoreKey onChange={triggerScore} key={'pad-' + 0} value={0}/>)
  keys.push(<BlankKey key={'pad-blank-r'}/>)


  return (
    <div className="row" style={{width: 800,margin: 10}}>
      <div className="col-md-6 col-sm-6 col-xs-6 text-center">
        <div className="padvalue">
          {value}
          <br/>
          <button onClick={submit} style={{padding: '6px 50px'}} className="btn btn-success">OK
          </button>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-6">
        <div className="row pad">
          {keys}
        </div>
      </div>
    </div>
  )
}

//
// class PadScorePadWrapper extends Component {
//   render() {
//     return (<ScoreWrapper onChange={this.props.onChange}>
//       {this.props.children}
//     </ScoreWrapper>)
//   }
// }


//
// class DiceScorePadWrapper extends Component {
//     constructor() {
//         super()
//         this.state = {
//             picked: []
//         }
//
//     }
//
//     triggerScore = (v) => {
//         if (this.props.onChange)  this.props.onChange(v)
//     }
//     add = (v) => {
//         if (this.state.picked.length < 5)
//             this.setState({
//                 picked: this.state.picked.concat(v)
//             })
//     }
//     remove = (v) => {
//         const p = this.state.picked
//         this.setState({
//             picked: p.slice(0, v).concat(p.slice(v + 1))
//         })
//     }
//     processScore = (v, idxRemove) => {
//
//         if (typeof idxRemove === 'number') {
//             this.remove(idxRemove)
//             return
//         }
//         this.add(v)
//     }
//
//     render() {
//         return (<ScoreWrapper onChange={this.triggerScore}>
//             {/*<ScorePad  onChange={this.triggerScore} conf={this.props.conf} />*/}
//             <DicePad picked={this.state.picked} onChange={this.processScore}/>
//             <br /><br />
//             <DicePad style={{transform: 'scale(.7)'}} onChange={this.processScore}/>
//         </ScoreWrapper>)
//     }
//
//     componentDidUpdate() {
//         if (this.state.picked.length === 5) {
//             setTimeout(()=> {
//                 this.triggerScore(this.state.picked.reduce((p, v)=> p + v), 0)
//             }, 300)
//
//         }
//     }
// }
//
// class ScoreComponent extends Component {
//
//   constructor() {
//     super()
//     this.state = {
//       edit: false
//     }
//   }
//
//   triggerScore = (v) => {
//     const {player, conf} = this.props
//     if (this.props.onChange) this.props.onChange(player, conf, v !== 'x' && v > 0 ? v + conf.config.bonus : v)
//     //if () this.toggleEditScore(false)
//     this.toggleEditScore(false)
//     //if (v === 'x' ||  (v + '').length === 2) this.toggleEditScore(false)
//   }
//
//   editScore = () => {
//     //const {player, conf} = this.props
//
//     //TODO SKIPPING DICE MODE
//     // MUST FIND A BETTER WAY
//     // const content = mode === 'dices' ?
//     //     <DiceScorePadWrapper onChange={this.triggerScore} conf={this.props.conf}/> :
//     //     <ScorePad onChange={this.triggerScore} conf={this.props.conf} deleteKey={true}/>
//
//     const content = <PadScorePadWrapper onChange={this.triggerScore}>
//       <ScorePad onChange={this.triggerScore} conf={this.props.conf} deleteKey={true}/>
//     </PadScorePadWrapper>
//
//       return <ScoreDialog
//         open={this.state.edit}
//         onClose={() => this.toggleEditScore(false)}
//       >
//           {content}
//       </ScoreDialog>
//
//     // return this.state.edit ?
//     //   <SimpleModalComponent title={conf.config.name + ' pour ' + player.name} content={content}
//     //                         close={e => this.toggleEditScore(false)}/> : null
//   }
//
//   toggleEditScore = (status) => {
//     this.setState({
//       edit: typeof status === 'boolean' ? status : !this.state.edit
//     })
//   }
//
//   render() {
//     const {player, conf} = this.props
//     const _score = player.scoreBoard.find(o => o.id === conf.id)
//     const score = _score ? _score.value : 0
//
//     return (<ScoreContainer level={this.props.conf}>
//       {this.editScore()}
//       <span onClick={this.toggleEditScore}>{_score ? score || '/' : ''}</span>
//     </ScoreContainer>)
//   }
// }




const CustomLevelComponent = ({level, players, className}) => {

  const scores = players.map(player => {
    return <ScoreComponent key={player.id} {...{player, level}}>
        <ScorePad {...{ player, level}}/>
    </ScoreComponent>
  })
  return (<LevelRow className={className} level={level}>{scores}</LevelRow>)
}

export default withPlayers(CustomLevelComponent)

//
// const CustomLevelComponent = ({players, level, settings, className}) => {
//   const saveScore = (player, level, v) => {
//     const playerScoreBoard = players.get(player.id).get('scoreBoard').filter(s => s.id !== level.id)
//     if (v !== 'x') {
//       playerScoreBoard.push({id: level.id, value: v})
//     }
//     players.get(player.id).set('scoreBoard', playerScoreBoard)
//   }
//
//   const scores = players.map(player => {
//     return <ScoreComponent mode={settings.mode} onChange={saveScore} key={player.id}
//                            player={player} conf={level}/>
//   })
//
//   return <LevelRow level={level} className={className}>{scores}</LevelRow>
// }

