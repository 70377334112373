import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import withGame from '../HOC/withGame'
import {newGameAction} from '../actions/gameActions'
import DialogContent from '@material-ui/core/DialogContent'

const ResetComponent = ({open, dispatch, onClose, closeDrawer}) => {
  const resetGame = () => {
    onClose()
    if(closeDrawer) closeDrawer()
    setTimeout(() => {
      dispatch(newGameAction())
    }, 400)

  }

  return (<Dialog
    fullWidth
    //maxWidth="md"
    open={open}
    onClose={onClose}>

    <DialogContent>
      <h4>Nouvelle partie ?</h4>
      <div style={{padding: 20}} className="text-center">
        <Button
          //color="default"
          onClick={onClose}
          style={{minWidth: 150,/* backgroundColor: '#F0F0F0'*/}}
        >
          Non
        </Button>
        <Button
          variant={'contained'}
          style={{minWidth: 150, marginLeft: 20}}
          color="primary"
          onClick={resetGame}
        >
          Nouvelle partie
        </Button>
      </div>
    </DialogContent>
  </Dialog>)
}

export default withGame(ResetComponent)