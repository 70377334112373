import React from 'react'
import GamePage from '../components/page/GamePage'

const Game = () => {
  return (
    <GamePage/>
  )
}


export default Game
