import uniq from "../../../utils/Unique";
import { getRoll } from "../../dice/utils/utils";
import { useEffect, useState } from "react";

export interface IDice {
  id: string;
  value: number;
  toggle?(): any;
  kept?: boolean;
}

interface ICreateDiceParams {
  toggleDice(params: any): any;
}

function createDice({ toggleDice }: ICreateDiceParams): IDice {
  const dice: IDice = {
    id: uniq(),
    value: getRoll(),
    kept: false,
  };
  dice.toggle = () => toggleDice(dice);
  return dice;
}

interface IUseRound {
  diceCount?: number | undefined;
  maxRollCount?: number | undefined;
}

interface GameState {
  state: "idle" | "onGoing" | "ended";
  rollNumber: number;
  rolls: IDice[];
}

function updateDice(rolls: IDice[], dice: IDice, status: boolean): IDice[] {
  const found = rolls.find((d) => d.id === dice.id);
  if (found) {
    found.kept = status;
  }
  return rolls;
}

function createArray(size: number): any {
  return new Array(size).fill("");
}
function createRolls(size: number, toggleDice: any): IDice[] {
  return createArray(size).reduce((s: IDice[]) => {
    return s.concat(createDice({ toggleDice }));
  }, []);
}

const initialState: GameState = { state: "idle", rollNumber: 0, rolls: [] };

const useRound = ({ diceCount = 5, maxRollCount = 3 }: IUseRound) => {
  /** STATES **/
  const [gameState, setGameState] = useState<GameState>(initialState);
  function setGameTo(status: "idle" | "onGoing" | "ended") {
    setGameState((state) => {
      return {
        ...state,
        state: status,
      };
    });
  }

  /** FUNCTIONS / CONTROLS **/
  function keepDice(dice: IDice): void {
    setGameState((state) => {
      return {
        ...state,
        rolls: updateDice(state.rolls, dice, true),
      };
    });
  }

  function removeDice(dice: IDice): void {
    setGameState((state) => {
      return {
        ...state,
        rolls: updateDice(state.rolls, dice, false),
      };
    });
  }

  function toggleDice(dice: IDice): void {
    if (dice.kept) {
      return removeDice(dice);
    }
    return keepDice(dice);
  }

  function play(): void {
    setGameState((state) => {
      const { rollNumber, rolls } = state;
      if (rollNumber >= maxRollCount) {
        return state;
      }
      const remainingDice = diceCount - rolls.filter((d) => d.kept).length;
      return {
        ...state,
        state: "onGoing",
        rollNumber: rollNumber + 1,
        rolls: rolls
          .filter((d) => d.kept)
          .concat(createRolls(remainingDice, toggleDice)),
      };
    });
  }

  function reset(): void {
    setGameState(initialState);
  }

  /** EFFECTS **/

  useEffect(() => {
    const { state, rollNumber, rolls } = gameState;
    if (
      state !== "ended" &&
      rollNumber === maxRollCount &&
      rolls.filter((d) => d.kept).length === diceCount
    ) {
      setGameTo("ended");
    }
  }, [maxRollCount, gameState, diceCount]);

  return {
    state: gameState.state,
    rolls: gameState.rolls,
    rollNumber: gameState.rollNumber,
    reset,
    play,
    keepDice,
    removeDice,
    toggleDice,
  };
};

export default useRound;
