import React from 'react'
import withPlayers from '../../players/HOC/withPlayers'

export const LevelRow = ({className, children, level}) =>  {
  const {name, bonus, title} = level.config

  return (<div className={'row ' + (className || '')}>
    <div className="col-md-4 col-sm-4 col-xs-4 score-title">
      <span className="fluid-typo">{title || name}</span>
      {level.type !== 'total' && bonus ? <span className="annotation">+ {bonus}</span> : null}
    </div>
    <div className="col-md-8 col-sm-8 col-xs-8" style={{padding: 0}}>
      <div className="score-cell-wrapper">
        {children}
      </div>
    </div>
  </div>)
}

export const ScoreContainer = withPlayers(({players, level, children, className}) => {
  const width = Math.round(100 / players.length) + '%'
  const played = level ? players[level.id] : null
  return (<div style={{width}} className={'score-cell ' + (played ? 'played ' : '') + (className || '')}>
    {children}
  </div>)
})
