import defaultLevels from './defaultLevels'

const defaultState = {
  collection: defaultLevels.levels.slice()
}

export const LEVELS_ACTION_MAPPER = {
  NEW_GAME: 'LEVELS_NEW_GAME',
}

function levels(state = defaultState, action) {
  switch (action.type) {
    case LEVELS_ACTION_MAPPER.NEW_GAME: {
      return {
        ...state,
        collection: defaultLevels.levels.slice()
      }
    }
    default: {
      return state
    }
  }

}


export default levels
