import React from 'react'
import { LevelRow, ScoreContainer } from '../../containers/LevelRow'
import withPlayers from '../../../players/HOC/withPlayers'

function Player (props) {
  /*const tag = props.player.image ?
      <span><div className="avatar" style={{backgroundImage:'url('+props.player.image+')'}}/>{props.player.name}</span> :
          <span>{props.player.name}</span>*/
  const tag = <span>{props.player.name}</span>
  return (<ScoreContainer className="name-wrapper">{tag}</ScoreContainer>)
}

const BoardHeader = ({players}) => {
  const scores = players.map( player => <Player key={player.id} player={player}/>)
  return (<LevelRow level={{config:{name:''}}}  className="player-row">{scores}</LevelRow>)
}

export default withPlayers(BoardHeader)