import React, { useContext, useEffect, useState } from "react"
import withPlayers from "../HOC/withPlayers"
import withGame from "../../game/HOC/withGame"
import { shuffle } from "../../../utils/arrays"
import "./next-player.scss"
import { getNextPlayer } from "../utils"
import usePrevious from "../../../hooks/usePrevious"
import Delay from "../../../layout/delay/Delay"
import DiceBoardContext from "../../dice/DiceBoardContext"

const animations = [
  "bounceInUp",
  "rubberBand",
  "lightSpeedIn",
  "shake",
  "swing",
  "tada",
  "wobble",
  "jello",
  "jackInTheBox",
]

const getRandomAnimation = () => shuffle(animations)[0]

const NextPlayer = withPlayers(
  withGame(({ players, game }) => {
    const player = getNextPlayer(players)
    const [animationName, setAnimationName] = useState(getRandomAnimation())
    const prevPlayerName = usePrevious(player.name)

    const imageStyle = {
      backgroundImage: `url(${player.image})`,
    }
    useEffect(() => {
      if (prevPlayerName !== player.name) {
        setAnimationName(getRandomAnimation)
      }
    }, [prevPlayerName, player, setAnimationName])

    if (game.status === "ended" || !player) return <div />

    return (
      <div className="next-player-wrapper">
        <Delay key={player.name} delay={800}>
          <div className={"player animated " + animationName}>
            <div className="image-wrapper">
              <div className={"image"} style={imageStyle} />
            </div>
            <div className="name">
              {player.name},<br />
              <span className="text-muted">à toi de jouer !</span>
            </div>
          </div>
        </Delay>
      </div>
    )
  })
)

export default NextPlayer
