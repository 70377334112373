import React, {useContext, useRef, useState} from 'react'
//import UserImagePlaceHolder from '../../../assets/placeholder.user.png'
import withPlayersHistory from '../HOC/withPlayersHistory'
import {Button, Menu, MenuItem} from '@material-ui/core'
import FieldComponent from '../../../layout/inputs/FieldComponent'
import {Add, Close, MoreVert} from '@material-ui/icons'
import useToggle from '../../../hooks/useTogglable'
import IconButton from '@material-ui/core/IconButton'
import withPlayerHistory from '../HOC/withPlayerHistory'
import useFormState from '../../../hooks/useFormState'
import {
  addPlayerHistoryPlayerAction,
  editPlayerHistoryPlayerAction,
  removePlayerHistoryPlayerAction
} from '../actions/playerHistoryActions'
import {Column} from '../../../layout/grid'
import Row from '../../../layout/grid/Row'
import withPlayers from '../HOC/withPlayers'
import {togglePlayerHandler} from './actionsShortcuts'
import {SoundContext} from '../../../layout/sound/contexts/SoundContext'




const EditPlayer = withPlayerHistory(({playersHistory, player, close, playerId, dispatch}) => {

  const [form, setForm] = useFormState(player)

  const deleteHandler = () => {
    dispatch(removePlayerHistoryPlayerAction(form))
    close()
  }
  const saveHandler = () => {
    dispatch(editPlayerHistoryPlayerAction(form))
    close()
  }

  return (
    <div className="animated fadeIn">
      <div>
        <FieldComponent name={'name'} placeholder="Nom"
                        onChange={setForm} value={form.name}/>
      </div>
      <div>
        <FieldComponent name={'image'} placeholder="Image"
                        onChange={setForm} value={form.image}/>
      </div>
      <Row className="no-gutters" style={{marginTop: 10}}>
        <Column size={6}>
          <Button onClick={close}>Annuler</Button>
        </Column>
        <Column size={6} className="text-right">
          <Button style={{marginRight: 10}} color={'secondary'} onClick={deleteHandler}><Close/> supprimer joueur</Button>
          <Button disableElevation color="primary" variant="contained" onClick={saveHandler}>Enregistrer</Button>
        </Column>

      </Row>
    </div>
  )


})

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  gridList: {
    width: '100%',
  },
  playerTile:{
    width: '25%',
    marginBottom: 10,
  },

  playerImageWrapper: {
  },
  playerName: {
    height: 48,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '48px',
    display: 'inline-block',
    position: 'relative'
  },
  playerNameWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: -48
  },
}



const PlayerTile = withPlayers(({onPickPlayer, player, editPlayer, dispatch, players}) => {

  const sound = useContext(SoundContext)
  const [open, toggle] = useToggle()
  const anchorEl = useRef()
  const playerIndex = players.findIndex(p => p.id === player.id)

  const edit = () => editPlayer(player.id)
  const deletePlayer = () => {
    dispatch(removePlayerHistoryPlayerAction(player))
  }
  const playSound = () => {
    if(playerIndex > -1) {
      return sound.unclick()
    }
    sound.click()
  }


  const onPickHandler = () => {
    playSound()
    onPickPlayer()
  }




  return (<div style={styles.playerTile}>
    <div style={styles.playerImageWrapper}>
      <div
        className={'player-tile-image ' + (playerIndex > -1 ? 'selected animated bounceIn' : '')}
        style={{
          ...styles.playerImage,
          ...(!!player.image && {backgroundImage: `url(${player.image})`})
        }}
        onClick={onPickHandler}
        //src={player.image || UserImagePlaceHolder} alt={player.name}
      >
        {playerIndex > -1 && (
          <div className="animated zoomIn player-chip">
            {/*<Check />*/}
            <span>{playerIndex+1}</span>
          </div>
        )}

      </div>
    </div>

    <div style={styles.playerNameWrapper}>
      <div style={styles.playerName}>
        {player.name}
        <IconButton style={styles.menu} ref={anchorEl} onClick={toggle}>
          <MoreVert style={{color: '#FFF'}}/>
        </IconButton>
      </div>


    </div>
    <React.Fragment>

      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => toggle(false)}
      >
        <MenuItem onClick={edit}>Editer</MenuItem>
        <MenuItem onClick={deletePlayer}>Supprimer</MenuItem>
      </Menu>
    </React.Fragment>


  </div>)
})

const AddTile = ({onClick}) => {
  return (<div style={styles.playerTile}>
    <div style={styles.playerImageWrapper}>
      <div
        className="player-tile-image"
        style={{
          backgroundImage: 'none',
          backgroundColor: '#2065d0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onClick}
        //src={player.image || UserImagePlaceHolder} alt={player.name}
      >
        <Add style={{fontSize: '48px'}} />
      </div>
    </div>

    <div style={styles.playerNameWrapper}>
      <div style={styles.playerName}>
       Ajouter Joueur
      </div>
    </div>


  </div>)

}

const PreviousPlayersAsChip = ({ playersHistory, dispatch}) => {

  const [playerId, editPlayer] = useState(null)

  const addPlayerHandler = () => {
    dispatch(addPlayerHistoryPlayerAction({ name: 'Nouveau Joueur'}))
  }

  return (<div>
    <div style={styles.root}>
      {playersHistory.map((player) => (
        <PlayerTile editPlayer={() => editPlayer(player.id)} key={player.id} player={player}
                    onPickPlayer={() => togglePlayerHandler(player)}/>
      ))}
      <AddTile onClick={addPlayerHandler} />
    </div>
    <div>
      {!!playerId && (
        <EditPlayer close={() => editPlayer(null)} playerId={playerId}/>
      )}
    </div>

  </div>)
}

export default withPlayersHistory(PreviousPlayersAsChip)