import React from 'react'
import moment from 'moment'
import {Column, Row} from '../../../layout/grid'

const PlayerListItem = ({date, player}) =>  {
  return (
    <Row className="no-gutters player">
      <Column size={1}>
        <div  className="avatar-wrapper">
          <div className="avatar" style={{backgroundImage: 'url(' + player.image + ')'}}/>
        </div>
      </Column>
      <Column size={3} className="name">{player.name}</Column>
      <Column size={2} className="score">{player.total}</Column>
      <Column size={6} className="score text-right"> {moment(date).format('LL')}</Column>
    </Row>
  )
}
export default PlayerListItem
