import React, { useContext } from "react";
import {
  Brush,
  Casino,
  Edit,
  Fullscreen,
  History,
  Mail,
  ScreenLockRotation,
  ScreenRotation,
} from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import withSettings from "../components/settings/HOC/withSettings";
import {
  toggleDiceMode,
  toggleLayout,
  toggleOrientationMode,
  toggleTheme,
} from "../components/settings/actions/actions";
import { setPickingAction } from "../components/game/actions/gameActions";
import FullscreenContext from "../components/fullscreen/FullscreenContext";
import StatsModalContext from "../components/leader-board/contexts/StatsModalContext";
import ResetComponent from "../components/game/components/ResetComponent";
import useToggle from "../hooks/useTogglable";
import withGame from "../components/game/HOC/withGame";
import { SoundContext } from "../layout/sound/contexts/SoundContext";

const styles = {
  container: {
    minWidth: 320,
  },
  subtitle: {
    marginTop: 30,
    padding: "0 20px",
    color: "#999",
    fontSize: "16px",
    margin: 0,
    lineHeight: "30px",
    textShadow: "0 1px 2px rgba(0,0,0,.8)",
  },
};

const Subtitle = ({ children }) => (
  <div style={styles.subtitle}>{children}</div>
);

export const SidebarMenu = withSettings(
  withGame(({ settings, game, toggle, dispatch }) => {
    const fullscreenContext = useContext(FullscreenContext);
    const statsContext = useContext(StatsModalContext);
    const sound = useContext(SoundContext);
    const [newGameDialogOpen, toggleNewGameDialog] = useToggle();

    const toggleNewGameDialogHandler = (arg) => {
      newGameDialogOpen ? sound.unclick() : sound.click();
      toggleNewGameDialog(arg);
    };

    const toggleOrientationDetectionHandler = () => {
      dispatch(
        toggleOrientationMode(
          settings.orientation === "manual" ? "auto" : "manual"
        )
      );
    };
    const toggleLayoutHandler = () => {
      sound.click();
      dispatch(
        toggleLayout(settings.layout === "vertical" ? "horizontal" : "vertical")
      );
    };
    const toggleThemeHandler = () => {
      sound.click();
      dispatch(
        toggleTheme(settings.theme === "default" ? "crimson" : "default")
      );
    };
    const toggleDiceModeHandler = () => {
      sound.click();
      dispatch(
        toggleDiceMode(settings.mode === "default" ? "dices" : "default")
      );
    };
    const editGameHandler = () => {
      toggle(false);
      sound.click();
      setTimeout(() => dispatch(setPickingAction()), 500);
    };
    const showStatsHandler = () => {
      toggle(false);
      sound.click();
      setTimeout(() => statsContext.toggle(), 500);
    };

    return (
      <div style={styles.container}>
        <ResetComponent
          closeDrawer={() => toggle(false)}
          open={newGameDialogOpen}
          onClose={() => toggleNewGameDialogHandler(false)}
        />

        <List>
          <ListItem button onClick={toggleNewGameDialogHandler}>
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary={"Nouvelle Partie"} />
          </ListItem>
          {game.status === "started" && (
            <ListItem button onClick={editGameHandler}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={"Modifier La Partie"} />
            </ListItem>
          )}

          <ListItem button>
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText onClick={showStatsHandler} primary={"Historique"} />
          </ListItem>

          <Subtitle>Mode</Subtitle>
          <ListItem button>
            <ListItemIcon>
              <Casino />
            </ListItemIcon>
            <ListItemText primary={"Dés"} />
            <ListItemSecondaryAction>
              <Switch
                checked={settings.mode === "dices"}
                onClick={toggleDiceModeHandler}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Subtitle>Theme</Subtitle>
          <ListItem button>
            <ListItemIcon>
              <Brush />
            </ListItemIcon>
            <ListItemText primary={"Theme"} />
            <ListItemSecondaryAction>
              <Switch
                checked={settings.theme !== "default"}
                onClick={toggleThemeHandler}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Subtitle>Paramètres</Subtitle>
          <ListItem button onClick={fullscreenContext.toggle}>
            <ListItemIcon>
              <Fullscreen />
            </ListItemIcon>
            <ListItemText primary={"Plein Ecran"} />
            <ListItemSecondaryAction>
              <Switch
                checked={fullscreenContext.status}
                onClick={fullscreenContext.toggle}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <ScreenLockRotation />
            </ListItemIcon>
            <ListItemText primary={"Automatique"} />
            <ListItemSecondaryAction>
              <Switch
                checked={settings.orientation === "auto"}
                onClick={toggleOrientationDetectionHandler}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <ScreenRotation />
            </ListItemIcon>
            <ListItemText primary={"Portrait"} />
            <ListItemSecondaryAction>
              <Switch
                checked={settings.layout === "vertical"}
                onClick={toggleLayoutHandler}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Subtitle>Version 0.2.3</Subtitle>
        </List>
      </div>
    );
  })
);

export default SidebarMenu;
