import React from 'react'
import {LevelRow, ScoreContainer} from '../../containers/LevelRow'
import withPlayers from '../../../players/HOC/withPlayers'


export function playerTotalGame (player) {
    const score = player.scoreBoard.reduce((prev,v)=>{
        return prev + v.value
    }, 0)

    const numberScore = player.scoreBoard.reduce((prev,v)=>{
        return prev + (/^nums/.test(v.id) ? v.value : 0 )
    }, 0)

    return score + (numberScore >= 60 ? 35 : 0)
}

class ScoreComponent extends React.Component {
    render() {
        const {player, conf} = this.props
        const bonus = conf.config.bonus || 0
        const min = conf.config.minForBonus
        const score = player.scoreBoard.reduce((prev, v) => {
            return prev + (/^nums/.test(v.id) ? v.value : 0 )
        }, 0)
        return (
            <ScoreContainer className="total">
                <span>{score + (score >= min ? bonus : 0 )}</span>
            </ScoreContainer>)
    }
}


class ScoreOtherComponent extends React.Component {
    render() {
        const {player} = this.props
        const score = player.scoreBoard.reduce((prev, v) => {
            return prev + (/^nums/.test(v.id) ? 0 : v.value )
        }, 0)
        return (
            <ScoreContainer className="total">
                <span>{score}</span>
            </ScoreContainer>)
    }
}

class TotalComponent extends React.Component {
    render() {
        const {player} = this.props
        const total = playerTotalGame(player)
        return (
            <ScoreContainer className="total">
                <span>{total}</span>
            </ScoreContainer>)
    }
}


function ScoreBonusComponent(props) {
    return (<ScoreContainer className="total">
        <span>{props.conf.config.value}</span>
    </ScoreContainer>)
}

const TotalLevelComponent = ({level, players, className}) =>  {

    const scores = players.map(player => {

        switch (level.id) {
            case 'bonus-a': {
                return <ScoreBonusComponent key={player.id} conf={level}/>
            }
            case 'total-b': {
                return <ScoreOtherComponent key={player.id} player={player} conf={level}/>
            }
            case 'total': {
                return <TotalComponent key={player.id} player={player} conf={level}/>
            }
            default: {
                return <ScoreComponent key={player.id} player={player} conf={level}/>
            }
        }
    })


    return (
      <LevelRow level={level} className={className}>{scores}</LevelRow>)
}

export default withPlayers(TotalLevelComponent)