
import {createMuiTheme} from '@material-ui/core';


const theme = createMuiTheme({
  palette:{
    mode: 'dark'
  },
  typography: {
    fontFamily: [
      'Poppins', "Helvetica Neue", 'Helvetica', 'Arial', 'sans-serif'
    ].join(','),
    useNextVariants: true
  }
});


export default theme
