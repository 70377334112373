import React, { useContext } from "react";
import { LevelRow } from "../../containers/LevelRow";
import { ScoreComponent } from "./SharedLevelComponent";
import { DiceFlatComponent } from "../../../dice/FlatDice";
import withPlayers from "../../../players/HOC/withPlayers";
import withSettings from "../../../settings/HOC/withSettings";
import { setMovePlayerAction } from "../../../players/actions/playersActions";
import store from "../../../../config/store";
import { ScoreModalContext } from "../../contexts/ScoreModalContext";
import { SoundContext } from "../../../../layout/sound/contexts/SoundContext";

const styles = {
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};
const ScoreKey = ({ value, level, player }) => {
  const context = useContext(ScoreModalContext);
  const { leverage, bonus } = level.config;
  const onChangeHandler = () => {
    const score = value * leverage + (value ? bonus : 0);
    store.dispatch(setMovePlayerAction({ player, level, score }));
    context.clearTurn();
  };
  return (
    <div
      className="col-md-4 col-sm-4 col-xs-4 text-center padkey"
      onClick={onChangeHandler}
    >
      {value || "/"}
    </div>
  );
};

const ScorePad = (props) => {
  const keys = [];
  for (let i = 1; i < 7; i++) {
    keys.push(
      <ScoreKey key={"e" + i + "p" + props.player.id} value={i} {...props} />
    );
  }
  return (
    <div className="row pad" style={{ width: 400 }}>
      {keys}
    </div>
  );
};

const DiceKey = ({ value, player, level }) => {
  const context = useContext(ScoreModalContext);
  const sound = useContext(SoundContext);
  const { leverage, bonus } = level.config;
  const onChangeHandler = () => {
    const score = value * leverage + (value ? bonus : 0);
    store.dispatch(setMovePlayerAction({ player, level, score }));
    sound.played();
    context.clearTurn();
  };
  return (
    <div
      style={styles.center}
      className="col-md-2 col-sm-2 col-xs-2"
      onClick={onChangeHandler}
    >
      <DiceFlatComponent faceUp={value} />
    </div>
  );
};

const DiceScorePadWrapper = (props) => {
  const keys = [];
  for (let i = 1; i < 7; i++) keys.push(i);
  return (
    <div className="row" style={{ width: 500 }}>
      {keys.map((i) => (
        <DiceKey {...props} key={"e" + i} value={i} />
      ))}
    </div>
  );
};

//
// class ScorePadWrapper extends React.Component {
//   render() {
//     return (<ScoreWrapper onChange={this.props.onChange}>
//       {this.props.children}
//     </ScoreWrapper>)
//   }
// }
//
// class ScoreComponent extends React.Component {
//
//   constructor() {
//     super()
//     this.state = {
//       edit: false
//     }
//   }
//
//   triggerScore = (v) => {
//     const {player, conf} = this.props
//     if (this.props.onChange) this.props.onChange(player, conf, v)
//     this.setState({
//       edit: false
//     })
//   }
//
//   editScore = () => {
//     const {mode} = this.props
//     const content = mode === 'dices' ?
//       <DiceScorePadWrapper onChange={this.triggerScore} conf={this.props.conf}/> :
//       <ScorePadWrapper onChange={this.triggerScore}
//                        children={<ScorePad onChange={this.triggerScore} conf={this.props.conf}/>}/>
//
//     return <ScoreDialog
//       open={this.state.edit}
//       onClose={() => this.toggleEditScore(false)}
//     >
//       {content}
//     </ScoreDialog>
//
//
//     // return this.state.edit ?
//     // <SimpleModalComponent title={ conf.config.name + ' pour ' + player.name } content={content}
//     //                       close={e => this.toggleEditScore(false)}/> : null
//   }
//
//   toggleEditScore = (status) => {
//     this.setState({
//       edit: typeof status === 'boolean' ? status : !this.state.edit
//     })
//   }
//
//
//   render() {
//     const {player, conf} = this.props
//     const _score = player.scoreBoard.find(o => o.id === conf.id)
//     const score = _score ? _score.value : 0
//     return (
//       <ScoreContainer level={this.props.conf}>
//         {this.editScore()}
//         <span onClick={this.toggleEditScore}>{_score ? score ? score : '/' : ''}</span>
//       </ScoreContainer>)
//   }
// }

const OfAKindLevelComponent = ({ level, players, className, settings }) => {
  const scores = players.map((player) => {
    return (
      <ScoreComponent key={player.id} {...{ player, level }}>
        {settings.mode === "dices" ? (
          <DiceScorePadWrapper {...{ player, level }} />
        ) : (
          <ScorePad {...{ player, level }} />
        )}
      </ScoreComponent>
    );
  });
  return (
    <LevelRow className={className} level={level}>
      {scores}
    </LevelRow>
  );
};

export default withSettings(withPlayers(OfAKindLevelComponent));
