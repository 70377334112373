const theme = {
    delay: 3500,
    animationDelay: 400,
    container: {
        zIndex: 10000,
        transition: `transform 400ms ease-out, visibility 400ms ease-out, opacity 400ms ease-out, top 400ms ease-out`
    },
    containerColors: {
        customToast: {backgroundColor: '#b35f9e'},
    }
}
export default theme