import React from 'react'
import PropTypes from 'prop-types'


/** TODO
 * REFACTOR ITS A MESS !
 * **/

export function Column({size, className, forceClassName, style, children, onClick}) {
  const _className = forceClassName || `col-md-${size} col-sm-${size} col-xs-${size} col-${size} ${className}`
  return (<div onClick={onClick} className={_className} style={style}>
    {children}
  </div>)
}

Column.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  forceClassName: PropTypes.string,
  style: PropTypes.object
}
Column.defaultProps = {
  size: 4,
  className: '',
  forceClassName: '',
  style: {}
}

export default Column
