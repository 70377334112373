import React, {useContext} from 'react'
import {LevelRow} from '../../containers/LevelRow'
import {ScoreComponent} from './SharedLevelComponent'
import withPlayers from '../../../players/HOC/withPlayers'
import {setMovePlayerAction} from '../../../players/actions/playersActions'
import store from '../../../../config/store'
import {ScoreModalContext} from '../../contexts/ScoreModalContext'
import checkIfScoreIsPlayable from '../../../game/utils/checkIfScoreIsPlayable'
import {popError} from '../../../../layout/toaster/toaster'
import {SoundContext} from '../../../../layout/sound/contexts/SoundContext'


const ScoreKey = ({value, weight, onChange}) => {
  const onChangeHandler = () => {
    if (onChange) onChange(value * weight)
  }
  const d = value * weight - value * 3
  let v = d > 0 ? '+' + d : d
  if (value === 1) {
    v = (weight === 0 ? '/' : 1 * weight)
  } else if (value > 1 && weight === 3) {
    v = '='
  }
  return (
    <div className="col-md-4 col-sm-4  col-xs-4 text-center padkey" onClick={onChangeHandler}>
      {v}
    </div>
  )
}


const ScorePad = (({level, player}) => {
  const context = useContext(ScoreModalContext)
  const sound = useContext(SoundContext)
  const onChangeHandler = (score) => {
    const rollsCount = [1, 2, 3, 4, 5]
    return new Promise((resolve, reject) => {
      if(score === 0) return resolve()
      return checkIfScoreIsPlayable({score, rollsCount, dices: [level.config.name]})
        .then(resolve)
        .catch(reject)
    }).then(() => {
      store.dispatch(setMovePlayerAction({player, level, score}))
      sound.played()
      context.clearTurn()
    }).catch(e => {
      popError('Ce score n\'est pas faisable !')
      sound.error()
    })


  }
  const {key} = level.config
  const keys = []
  for (let i = 0; i < 6; i++) {
    keys.push(<ScoreKey onChange={onChangeHandler} key={key + '-' + i} weight={i} value={key}/>)
  }
  return (
    <div className="row pad" style={{width: 400}}>
      {keys}
    </div>
  )
})


const NumLevelComponent = ({level, players, className}) => {
  const scores = players.map(player => {
    return <ScoreComponent key={player.id} player={player} level={level}>
      <ScorePad player={player} level={level}/>
    </ScoreComponent>
  })
  return (<LevelRow className={className} level={level}>{scores}</LevelRow>)
}

export default withPlayers(NumLevelComponent)