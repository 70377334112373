import uniq from '../../../utils/Unique'
import EncodedStorage from '../../../utils/EncodedStorage'
import {getInitialState} from '../../reducers-tools/reducers-tools'

const _players = [
  {
    id: 1,
    name: 'Alaa',
    image: 'http://api.sunny.fr/images/mustache.jpg',
    scoreBoard: []
  },
  {
    id: 2,
    name: 'Suzy',
    image: 'http://api.sunny.fr/images/sooz.jpg',
    scoreBoard: []
  },
  {
    id: 3,
    name: 'Cyril',
    image: 'http://api.sunny.fr/images/cyril.jpg',
    scoreBoard: []
  },
  {
    id: 4,
    name: 'JM',
    image: 'http://api.sunny.fr/images/jm.jpg',
    scoreBoard: []
  },
  {
    id: 5,
    name: 'MC',
    image: 'http://api.sunny.fr/images/mc.jpg',
    scoreBoard: []
  },
  {
    id: 6,
    name: 'Julie',
    image: 'http://api.sunny.fr/images/julie.jpg',
    scoreBoard: []
  },
  {
    id: 7,
    name: 'Martine',
    image: 'http://api.sunny.fr/images/martinus.jpg',
    scoreBoard: []
  },
  {
    id: 8,
    name: 'Thomas',
    image: 'http://api.sunny.fr/images/thomas.jpg',
    scoreBoard: []
  },
  {
    id: 9,
    name: 'Patrick',
    image: 'http://api.sunny.fr/images/patrick.jpg',
    scoreBoard: []
  },
  {
    id: 10,
    name: 'Nico',
    image: 'http://api.sunny.fr/images/nico.jpg',
    scoreBoard: []
  },
  {
    id: 11,
    name: 'Popo',
    image: 'http://api.sunny.fr/images/pauline.jpg',
    scoreBoard: []
  }
]





export const PLAYER_HISTORY_ACTION_MAPPER = {
  PLAYER_HISTORY_EDIT: 'PLAYER_HISTORY_EDIT',
  PLAYER_HISTORY_REMOVE: 'PLAYER_HISTORY_REMOVE',
  PLAYER_HISTORY_ADD: 'PLAYER_HISTORY_ADD'
}

const STATE_STORAGE_KEY = '@yamsy/PLAYER_HISTORY'
const storage = new EncodedStorage({key: STATE_STORAGE_KEY})
const defaultState = getInitialState(storage, {collection: _players.concat()})
const saveState = (state) => {
  storage.set(JSON.stringify(state))
  return state
}


const editPlayer = (state, {player}) => {
  const found = state.collection.find(p => p.id === player.id)
  if (!found) return state
  Object.assign(found, player)
  return {
    ...state,
    collection: [...state.collection]
  }
}

const removePlayer = (state, {player}) => {
  const found = state.collection.find(p => p.id === player.id)
  if (!found) return state
  return {
    ...state,
    collection: state.collection.filter(p => p.id !== player.id)
  }
}

const addPlayer = (state, {player}) => {
  //, id: uniq()
  return {
    ...state,
    collection: state.collection.concat({...player, id: player.id || uniq() })
  }
}

function playersHistory(state = defaultState, action) {
  switch (action.type) {
    case PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_EDIT: {
      return saveState(editPlayer(state, action.payload))
    }
    case PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_REMOVE: {
      return saveState(removePlayer(state, action.payload))
    }
    case PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_ADD: {
      return saveState(addPlayer(state, action.payload))
    }
    default: {
      return state
    }
  }

}


export default playersHistory
