import EncodedStorage from '../../../utils/EncodedStorage'
import {getInitialState} from '../../reducers-tools/reducers-tools'


const STATE_STORAGE_KEY = '@yamsy/PLAYERS'
const storage = new EncodedStorage({key: STATE_STORAGE_KEY})
const defaultState = getInitialState(storage, {collection: [].concat()})

const saveState = (state) => {
  storage.set(JSON.stringify(state))
  return state
}

const defaultPlayer = () => {
  return {
    name:'',
    image:'',
    scoreBoard: [].slice()
  }
}

const createPlayer = (player) => {
  return {
    ...defaultPlayer(),
    ...player
  }
}


const addPlayer = (state, {player}) => {
  return {
    ...state,
    collection: state.collection.concat(createPlayer(player))
  }
}
const removePlayer = (state, {player}) => {
  return {
    ...state,
    collection: state.collection.filter(p => p.id !== player.id)
  }
}

const togglePlayer = (state, {player}) => {
  const found = state.collection.find(p => p.id === player.id)
  if(found) return removePlayer(state, {player})
  return addPlayer(state, {player})
}
const newGame = (state) => {
  return {
    ...state,
    collection: [].concat()
  }
}

const setMove = (state, {player, level, score}) => {
  const found = state.collection.find(p => p.id === player.id)
  if(!found) {
    console.log('player not found')
    return state
  }
  found.scoreBoard = found.scoreBoard.filter(s => s.id !== level.id).concat({id: level.id, value: score})
  return {
    ...state,
    collection: [...state.collection]
  }
}

const clearMove = (state, {player, level}) => {
  const found = state.collection.find(p => p.id === player.id)
  if(!found) {
    console.log('player not found')
    return state
  }
  found.scoreBoard = found.scoreBoard.filter(s => s.id !== level.id)
  return {
    ...state,
    collection: [...state.collection]
  }
}



export const PLAYERS__ACTION_MAPPER = {
  NEW_GAME: 'PLAYERS_NEW_GAME',
  ADD_PLAYER: 'PLAYERS_ADD_PLAYER',
  REMOVE_PLAYER: 'PLAYERS_REMOVE_PLAYER',
  TOGGLE_PLAYER: 'PLAYERS_TOGGLE_PLAYER',
  SET_MOVE: 'PLAYERS_SET_MOVE',
  CLEAR_MOVE: 'PLAYERS_CLEAR_MOVE'
}

function players(state = defaultState, action) {
  switch (action.type) {
    case PLAYERS__ACTION_MAPPER.NEW_GAME: {
      return saveState(newGame(state))
    }
    case PLAYERS__ACTION_MAPPER.ADD_PLAYER: {
      return saveState(addPlayer(state, action.payload))
    }
    case PLAYERS__ACTION_MAPPER.REMOVE_PLAYER: {
      return saveState(removePlayer(state, action.payload))
    }
    case PLAYERS__ACTION_MAPPER.TOGGLE_PLAYER: {
      return saveState(togglePlayer(state, action.payload))
    }
    case PLAYERS__ACTION_MAPPER.SET_MOVE: {
      return saveState(setMove(state, action.payload))
    }
    case PLAYERS__ACTION_MAPPER.CLEAR_MOVE: {
      return saveState(clearMove(state, action.payload))
    }
    default: {
      return state
    }
  }

}


export default players
