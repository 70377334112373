import React, { useState, useEffect } from "react";
import { SwipeableDrawer, withStyles } from "@material-ui/core";
import "./sidebar.scss";
import SidebarMenu from "../../pages/SidebarMenu";

const styles = () => ({
  drawerPaperWidthDesktop: {
    width: 110,
    backgroundColor: "rgb(38, 50, 56)",
  },
  drawerPaperMobile: {
    width: 400,
    backgroundColor: "rgb(38, 50, 56)",
  },
});

const getLayout = () => {
  const width = window.document.body.clientWidth;
  return width < 800 ? "drawerPaperMobile" : "drawerPaperWidthDesktop";
};

const SideBarComponent = ({ open, toggle, classes }) => {
  const [, setState] = useState({
    drawerClass: "drawerPaperMobile",
    anchor: "left",
  });

  //const { anchor, drawerClass } = state

  useEffect(() => {
    const setSize = () =>
      setState({
        drawerClass: getLayout(),
      });
    setSize();
    window.addEventListener("resize", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
    };
  }, [setState]);

  return (
    <SwipeableDrawer
      //variant="persistent"
      anchor={"left"}
      open={open}
      onClose={() => toggle(false)}
      onOpen={() => toggle(true)}
      //classes={{ paper: classes[drawerClass], root: classes['rootDesktop'] }}
    >
      <SidebarMenu style={{ marginTop: 60 }} toggle={() => toggle(false)} />
    </SwipeableDrawer>
  );
};
export default withStyles(styles, { withTheme: true })(SideBarComponent);
