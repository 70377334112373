


export function getTotals(players) {
  return players.map(player => {
    const score = player.scoreBoard.reduce((prev, v)=> {
      return prev + v.value
    }, 0)
    const numberScore = player.scoreBoard.reduce((prev, v)=> {
      return prev + (/^nums/.test(v.id) ? v.value : 0 )
    }, 0)
    return {...player, total: score + (numberScore >= 60 ? 35 : 0)}
  }).sort((a, b) => b.total - a.total)
}



export const isAllTurnsDone = ({players, levels}) => {
  const played = players.reduce((prev, player)=> {
    return prev + player.scoreBoard.length
  }, 0)

  const totalLevel = levels.length - 5
  return played > 0 && played === totalLevel * players.length
}

//
// export const isGameOver = ({levels, players, game}) => {
//   const allTurnsDone = isAllTurnsDone({players, levels})
//
//   if (allTurnsDone) {
//
//     const totals = getTotals(players)
//     const prev = playedGamesHistory.find(g => g.id === game.id)
//     if(!prev) {
//       // new game has ended
//       console.log(game)
//       //store.dispatch(savePlayedGameAction({game, players}))
//       if(game.status !== 'ended') {
//         //store.dispatch(endGameAction())
//       }
//
//
//     } else {
//       // editing previous game
//       //const prevDate = prev.date
//
//     }
//
//   console.log(prev)
//
//     // const prevDate = prev ? prev.get('date') : null
//     // collection.remove(prev)
//     // collection.add({
//     //   id: this.store.game.id,
//     //   date: prevDate || new Date().getTime(),
//     //   players: totals,
//     //   winner: totals[0]
//     // })
//     //
//     // this.store.game.set('status', 'ended')
//   }
// }