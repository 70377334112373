import React, { useEffect, useState } from "react"
import HorizontalLayout from "./HorizontalLayout"
import withPlayers from "../players/HOC/withPlayers"
import withSettings from "../settings/HOC/withSettings"
import withLevels from "../levels/HOC/withPlayers"
import withGame from "../game/HOC/withGame"
import NextPlayer from "../players/components/NextPlayer"
import NewGameSettings from "../game/components/NewGameSettings"
import EndGameComponent from "../game/components/EndGameComponent"
import { ScoreModalContext } from "../levels/contexts/ScoreModalContext"
import { isAllTurnsDone } from "../game/utils/utils"
import withPlayedGamesHistory from "../players/HOC/withPlayedGamesHistory"
import { endGameAction } from "../game/actions/gameActions"
import { savePlayedGameAction } from "../players/actions/playedGamesHistoryActions"
import "./board.scss"
import VerticalLayout from "./VerticalLayout"
import DiceBoard from "../dice/DiceBoard"
import DiceBoardContext from "../dice/DiceBoardContext"
import useToggle from "../../hooks/useTogglable"
import CallDiceBoard from "../dice/CallDiceBoard"

const ScoreBoard = ({
  players,
  settings,
  levels,
  game,
  dispatch,
  //playedGamesHistory,
}) => {
  const [turn, setTurn] = useState({
    playerId: null,
    levelId: null,
  })
  const [open, toggle] = useToggle(false)

  const { status } = game

  const allTurnsAreDone = isAllTurnsDone({ players, levels })

  useEffect(() => {
    if (allTurnsAreDone) {
      dispatch(endGameAction())
    }
  }, [allTurnsAreDone, dispatch])

  useEffect(() => {
    if (game.status === "ended") {
      dispatch(savePlayedGameAction({ game, players }))
    }
  }, [game, players, dispatch])

  const BoardLayout =
    settings.layout === "horizontal" ? HorizontalLayout : VerticalLayout

  return (
    <React.Fragment>
      <ScoreModalContext.Provider
        value={{
          turn,
          setTurn: setTurn,
          clearTurn: () => setTurn({ playerId: null, levelId: null }),
        }}
      >
        <DiceBoardContext.Provider value={{ open, toggle }}>
          <div className="main-board">
            {(status === "started" || status === "ended") && (
              <BoardLayout
                key={settings.layout}
                players={players}
                settings={settings}
                levels={levels}
              />
            )}
          </div>

          {status === "started" && <NextPlayer />}
          {status === "picking" && <NewGameSettings />}
          {status === "ended" && <EndGameComponent />}

          <DiceBoard />
          <CallDiceBoard />
        </DiceBoardContext.Provider>
      </ScoreModalContext.Provider>
    </React.Fragment>
  )
}

export default withPlayedGamesHistory(
  withSettings(withPlayers(withGame(withLevels(ScoreBoard))))
)
