import React, { useContext } from "react"
import useToggle from "../../hooks/useTogglable"
import navigate from "../../config/navigate"
import { SoundContext } from "../../layout/sound/contexts/SoundContext"
import StatsModalContext from "../leader-board/contexts/StatsModalContext"
import { IconButton } from "@material-ui/core"
import { Home, Settings } from "@material-ui/icons"
import SideBarComponent from "../../layout/sidebar/SideBarComponent"
import StatsDialog from "../game/components/StatsDialog"
import ScoreBoard from "../board/ScoreBoard"

const styles = {
  center: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
  },
}

const GamePage = () => {
  const [drawOpen, toggleDrawer] = useToggle()
  const [statsDialog, toggleStatsDialog] = useToggle()
  const sound = useContext(SoundContext)

  const navigateToHomeHandler = () => navigate("/")

  const toggleDrawerHandler = (arg) => {
    drawOpen ? sound.unclick() : sound.click()
    toggleDrawer(arg)
  }

  return (
    <StatsModalContext.Provider
      value={{
        open: statsDialog,
        toggle: toggleStatsDialog,
      }}
    >
      <div style={{ height: "100vh" }} className="animated fadeInRightShort">
        <div className="board-wrapper">
          <div style={styles.center}>
            <IconButton onClick={navigateToHomeHandler}>
              <Home />
            </IconButton>
            <IconButton onClick={toggleDrawerHandler}>
              <Settings />
            </IconButton>
          </div>

          <SideBarComponent open={drawOpen} toggle={toggleDrawerHandler} />

          <StatsDialog />

          <ScoreBoard />
        </div>
      </div>
    </StatsModalContext.Provider>
  )
}

export default GamePage
