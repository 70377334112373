import React from 'react'
import BadgeList from '../../game/components/BadgeListComponent'
import moment from 'moment'


const Player = ({date, player}) => {
  return (
    <div className="player">
      <div className="wrapper">
        <div className="avatar" style={{backgroundImage: 'url(' + player.image + ')'}}/>
      </div>
      <span className="name">{player.name}</span>
      <span className="score">{player.total}</span>
      <div>
        {BadgeList(player)}
      </div>
      {!!date && (
        <div style={{marginTop: '5px'}}>
          <span> {moment(date).format('LL')}
          </span>
        </div>
      )}
    </div>
  )
}


export default Player
