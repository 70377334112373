
const defaultDices = [1, 2, 3, 4, 5, 6].sort().reverse();

export const getAllPossibleValues = (rolls, goal) => {

  return rolls.reduce((state, roll) => {
    const v = roll.reduce((t, v) => t + v, 0);

    if(goal === v) {
      return state.concat([roll])
    }
    return state
  }, [])


};

const singleton = {}

export const getAllPlayableRolls = ({rollsCount, dices}) => {

  const unique_calc_id = `${rollsCount}-${dices.sort().join(':')}`

  if(singleton[unique_calc_id]) return singleton[unique_calc_id]

  const rolls = [];
  const uniques = {};
  const compute = (depth, tmp) => {
    if (depth === rollsCount ) {
      const uniqueCombination = tmp.slice().sort().join("-");
      if (!uniques[uniqueCombination]) {
        uniques[uniqueCombination] = tmp.reduce((t, v) => t + v, 0);
        rolls.push(tmp);
      }
      return;
    }
    const acc = depth === 0 ? [] : tmp;
    for (let i = 0; i < dices.length; i++) {
      const diceValue = dices[i];
      compute(depth + 1, acc.concat(diceValue));
    }
  };
  compute(0);
  singleton[unique_calc_id] = { rolls }
  return singleton[unique_calc_id]
}


export const getRolls = ({rollsCount, dices}) => {
  if(Array.isArray(rollsCount)) {
    return rollsCount.reduce((acc, i) => {
      return acc.concat(getAllPlayableRolls({rollsCount: i, dices}).rolls)
    }, [])
  }
  return getAllPlayableRolls({rollsCount, dices}).rolls
}


const checkIfScoreIsPlayable = ({ score = 0, rollsCount = 5, dices = defaultDices }) => {
  return new Promise((resolve, reject) => {
    const rolls = getRolls({rollsCount, dices})
    const valid = getAllPossibleValues(rolls, score);
    if (valid.length > 0) {
      return resolve(valid);
    }
    reject("No combination found");
  });
};


export default checkIfScoreIsPlayable

// const onError = (r) => console.log(r);
// const onSuccess = console.log
// const playable = checkIfScoreIsPlayable({
//   score: 5,
//   rollsCount: 5,
//   dices
// });
// playable
//   .then(onSuccess)
//   .catch(onError)


