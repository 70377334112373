import {PLAYER_HISTORY_ACTION_MAPPER} from '../reducers/playersHistory'

export const editPlayerHistoryPlayerAction = (player) => {
  return (dispatch) => {
    dispatch({type: PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_EDIT, payload: {player}})
  }
}
export const removePlayerHistoryPlayerAction = (player) => {
  return (dispatch) => {
    dispatch({type: PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_REMOVE, payload: {player}})
  }
}
export const addPlayerHistoryPlayerAction = (player) => {
  return (dispatch) => {
    dispatch({type: PLAYER_HISTORY_ACTION_MAPPER.PLAYER_HISTORY_ADD, payload: {player}})
  }
}