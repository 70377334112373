//picking
//ready
//started
//ended

import EncodedStorage from '../../../utils/EncodedStorage'
import {getInitialState} from '../../reducers-tools/reducers-tools'
import uniq from '../../../utils/Unique'


const STATE_STORAGE_KEY = '@yamsy/GAME'
const storage = new EncodedStorage({key: STATE_STORAGE_KEY})
const defaultState = getInitialState(storage, {id: uniq(), status: 'ended'})


const saveState = (state) => {
  storage.set(JSON.stringify(state))
  return state
}


const newGame = (state) => {
  return {
    ...state,
    id: uniq(),
    status: 'picking'
  }
}

const startGame = (state) => {
  return {
    ...state,
    status: 'started'
  }
}

const setPicking = (state) => {
  return {
    ...state,
    status: 'picking'
  }
}

const endGame = (state) => {
  return {
    ...state,
    status: 'ended'
  }
}

export const GAME_ACTION_MAPPER = {
  NEW_GAME: 'GAME_NEW_GAME',
  START: 'GAME_START',
  END: 'GAME_END',
  PICKING: 'GAME_PICKING'
}

function game(state = defaultState, action) {
  switch (action.type) {
    case GAME_ACTION_MAPPER.NEW_GAME: {
      return saveState(newGame(state))
    }
    case GAME_ACTION_MAPPER.START: {
      return saveState(startGame(state))
    }
    case GAME_ACTION_MAPPER.END: {
      return saveState(endGame(state))
    }
    case GAME_ACTION_MAPPER.PICKING: {
      return saveState(setPicking(state))
    }
    default: {
      return state
    }
  }

}


export default game
