import React from 'react'
import './header.scss'
// import useToggle from '../../hooks/useTogglable'
// import AppBar from '@material-ui/core/AppBar'
//import { makeStyles } from '@material-ui/core/styles';
// import Toolbar from '@material-ui/core/Toolbar'
// import IconButton from '@material-ui/core/IconButton'
// import {
//
//   Fullscreen,
//   Menu,
//   More,
//   People,
//   Settings,
//   ShowChart
// } from '@material-ui/icons'
// import Typography from '@material-ui/core/Typography'
// import toggleFullScreen from '../../components/fullscreen/fullscreen'
// import Dialog from '@material-ui/core/Dialog'
//
//
// const useStyles = makeStyles((theme) => ({
//   grow: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     display: 'none',
//     [theme.breakpoints.up('sm')]: {
//       display: 'block',
//     },
//   },
//
//   sectionDesktop: {
//     display: 'none',
//     [theme.breakpoints.up('md')]: {
//       display: 'flex',
//     },
//   },
//   sectionMobile: {
//     display: 'flex',
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
// }));
//
// const Header = ({sidebarOpen, sidebarToggle}) => {
//   const [visible, toggle] = useToggle(false)
//   const classes = useStyles();
//
//
//
//   return (
//     <div className={classes.grow}>
//       <Dialog
//         open={visible}
//         onClose={() =>toggle(false)}
//       >
//         hello
//       </Dialog>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             onClick={sidebarToggle}
//             edge="start"
//             className={classes.menuButton}
//             color="inherit"
//             aria-label="open drawer"
//           >
//             <Menu />
//           </IconButton>
//           <Typography className={classes.title} variant="h6" noWrap>
//             Yamsy
//           </Typography>
//           <div className={classes.search}>
//
//           </div>
//           <div className={classes.grow} />
//           <div className={classes.sectionDesktop}>
//             <IconButton color="inherit" onClick={toggleFullScreen}>
//               <Fullscreen />
//             </IconButton>
//             <IconButton color="inherit" onClick={toggle} >
//               <People />
//             </IconButton>
//             <IconButton color="inherit" onClick={toggle} >
//               <ShowChart />
//             </IconButton>
//             <IconButton color="inherit" edge="end" onClick={toggle} >
//               <Settings />
//             </IconButton>
//
//           </div>
//           <div className={classes.sectionMobile}>
//             <IconButton
//               //aria-controls={mobileMenuId}
//               //onClick={handleMobileMenuOpen}
//             >
//               <More />
//             </IconButton>
//           </div>
//         </Toolbar>
//       </AppBar>
//     </div>
//   )
//
// }

const Header = () => {
  return <div/>
}

export default Header
