import React from 'react'
import LevelComponent from '../levels/components/LevelComponent'
import BoardHeader from '../levels/components/types/BoardHeader'


//
// class VerticalLayout extends React.Component {
//   render() {
//     const {levels, players, settings} = this.props
//     return (<div className={'board  ' +settings.theme }>
//       <PlayerLevelComponent players={players}/>
//       {levels}
//     </div>)
//   }
// }

const VerticalLayout = ( {levels = [], settings = {}}) => {
  const collection = levels.map(level => (
    <LevelComponent settings={settings} key={level.id} level={level}/>
    ))


  return (
    <div className={'animated fadeIn board  ' +settings.theme }>
      <BoardHeader />
      {collection}
    </div>
  )
}

export default VerticalLayout
