import React, {useState} from 'react'
import moment from 'moment'
import {Icon, Fab, Button} from '@material-ui/core'
import {Badge, BadgeList, fontStyle} from './BadgeListComponent'
import withPlayedGamesHistory from '../../players/HOC/withPlayedGamesHistory'
import useToggle from '../../../hooks/useTogglable'
import uniq from '../../../utils/Unique'
import Player from '../../leader-board/components/Player'
import PlayerListItem from '../../leader-board/components/PlayerListItem'
import {playerTotalGame} from '../../levels/components/types/TotalLevelComponent'
import {Close} from '@material-ui/icons'
import 'moment/locale/fr'

moment.locale('fr')

const styles = {
  row: {
    lineHeight: '50px',
    height: '50px',
    backgroundColor: 'rgba(0,0,0,.1)',
    cursor: 'pointer',
    borderBottom: '1px solid rgba(255,255,255,.05)'
  },
  floatingButton: {
    marginRight: 20,
    position: 'absolute',
    bottom: -20,
    right: 20
  },
  center: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dateTitle: {
    display:'flex',
    alignItems: 'center',
    justifyContent:'flex-start',
    height: '100%',
    fontSize: '16px',
    fontWeight: 700
  }
}


const CloseButton = ({close}) => {
  return (<Fab onClick={close} style={styles.floatingButton}>
    <Close/>
  </Fab>)
}


function playerGameResult({game, size}) {
  const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
  return game.players.sort((a, b) => a.name.localeCompare(b.name)).map(player => {
    const winner = player.name === game.winner.name //? <FontIcon className="fa fa-trophy" />: null
    const style = {padding: '10px 10px'}
    const wstyle = winner ? {
      ...style,
      backgroundColor: 'rgba(255,255,255,.05)'
    } : style
    return (<div className={classSize} style={wstyle} key={player.name}>
      <div style={{}} className="pull-right">
        {player.total}
      </div>
      <div className="">
        {winner ? <strong>{player.name}</strong> : player.name}
      </div>
      <div className="pull-left" style={{marginTop: '10px'}}>
        {winner ?
          <Badge title="Gagnant !"><Icon style={fontStyle} className="fa fa-trophy"/></Badge> : null}
        {BadgeList(player)}
      </div>
    </div>)
  })
}


const GameResult = ({game, size = 4, disableDate}) => {
  const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
  return (<div className="row no-gutters px-list-item">
    <div className={classSize} style={{padding: '10px 10px'}}>
      <div style={styles.dateTitle}>
        {disableDate ? ' ' : moment(game.date).format('LLL')}
      </div>
    </div>
    {playerGameResult({game, size: size})}
  </div>)
}

const TotalPlayersResult = ({player, size = 4, style, prop = 'totals'}) => {
  const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
  return (<div className={classSize + ' text-center'} style={{padding: '10px 10px', ...style}}>
    {player[prop]}
  </div>)
}

function getTotals(games) {
  return games.reduce((prev, current) => {
    // UPDATING LIST OF PLAYERS
    current.players.forEach(player => {
      if (!prev.find(o => o.name === player.name)) {
        prev.push({
          name: player.name,
          wins: 0,
          totals: 0,
          badges: 0
        })
      }
    })

    //counting victories
    const item = prev.find(o => o.name === current.winner.name)
    item.wins++

    //All Points
    current.players.forEach(player => {
      const item = prev.find(o => o.name === player.name)
      item.totals += playerTotalGame(player)
    })

    //badge count
    current.players.forEach(player => {
      const item = prev.find(o => o.name === player.name)
      const winner = current.winner.name === player.name ? 1 : 0
      item.badges += BadgeList(player).length + winner
    })

    return prev
  }, [])
}



const Podium = ({games, style}) => {
  return (
    <div style={style} className="podium">
      <div className="leaders">
        {games.concat().slice(0, 3).map(game => (
          <Player date={game.date} key={game.id || uniq()} player={game.winner}/>
        ))}
      </div>
      <div className="others">
        {games.concat().slice(3, 10).map(game => (
          <PlayerListItem date={game.date} key={game.id || uniq()} player={game.winner}/>
        ))}
      </div>
    </div>
  )
}

const TotalResult = ({games, size}) => {

  const totals = (games, style, size) => {
    const totals = getTotals(games).map(player => <TotalPlayersResult style={style} key={player.name} player={player}/>)
    const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
    return (<div className="row px-list-item" style={{backgroundColor: '#999', color: '#323232', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
      <div className={classSize} style={style}>
        <strong>Total points</strong>
      </div>
      {totals}
    </div>)
  }

  const badges = (games, style, size) => {
    const totals = getTotals(games).map(player => <TotalPlayersResult prop={'badges'} style={style} key={player.name}
                                                                      player={player}/>)
    const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
    return (<div className="row" style={{backgroundColor: '#999', color: '#323232', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
      <div className={classSize} style={style}>
        <strong>Nombre de badges</strong>
      </div>
      {totals}
    </div>)
  }


  const victories = (games, style, size) => {
    const totals = getTotals(games).map(player => <TotalPlayersResult prop={'wins'} style={style} key={player.name}
                                                                      player={player}/>)
    const classSize = `col-md-${size} col-xs-${size} col-sm-${size}`
    return (<div className="row" style={{backgroundColor: '#999', color: '#323232'}}>
      <div className={classSize} style={style}>
        <strong>Nombre de victoires</strong>
      </div>
      {totals}
    </div>)
  }

  const topChart = (games, style) => {

    const topGames = games.sort(scoreSorter).reverse()

    return (<div style={{margin: '20px 0', marginTop: 30}}>
      <div className="row" style={{...style, backgroundColor: '#FFF', color:'#323232'}}>
        <strong>Meilleurs Scores</strong>
      </div>
      <Podium style={{marginTop: 60}} games={topGames}/>
    </div>)
  }


  const style = {padding: '10px 10px', lineHeight: '40px'}

  const _size = size || 4

  return (<div>
    {totals(games, style, _size)}
    {badges(games, style, _size)}
    {victories(games, style, _size)}
    {topChart(games, style, _size)}
  </div>)
}


function dateSorter(a, b) {
  const da = new Date(a.date)
  const db = new Date(b.date)
  if (da < db) return -1
  if (da > db) return 1
  return 0
}

function scoreSorter(a, b) {
  const da = a.winner.total
  const db = b.winner.total
  if (da < db) return -1
  if (da > db) return 1
  return 0
}


const DayComponent = ({games, title}) => {
  const [open, toggle] = useToggle()
  const players = getTotals(games)

  // const gamesList = games.map((item) => (<GameResult disableDate={true} key={item.id} game={item}/>))
  // gamesList.push(<TotalResult games={games} key="totals" />)

  return (<div>
    <div className="row" style={styles.row} onClick={toggle}>
      <div className="col-md-4 col-sm-4">
        {title}
      </div>
      {players.map(player => {
        return (<div className="col-md-4 col-sm-4 text-center" key={title + '-' + player.name}>
          {player.name} <strong>{player.wins}</strong>
        </div>)
      })}
    </div>
    {open && (
      <div className="animated fadeIn px-list" style={{position: 'relative'}}>
        {games.map((item) => (<GameResult disableDate={true} key={item.id} game={item}/>))}
        <TotalResult games={games} key="totals"/>
      </div>
    )}
  </div>)
}


const GamesPlayedHistory = ({playedGamesHistory}) => {
  return playedGamesHistory.sort(dateSorter).reverse().map(item => <GameResult key={item.id} game={item}/>)
}
const DailyGamesPlayedHistory = ({playedGamesHistory}) => {
  const list = playedGamesHistory.sort(dateSorter).reverse().reduce((prev, current) => {
    const day = moment(current.date).format('LL')
    const item = prev.find(o => o.day === (day))
    if (!item) {
      prev.push({
        day,
        games: [current]
      })
    } else {
      item.games.push(current)
    }
    return prev
  }, [])

  return list.map(item => <DayComponent key={item.day} games={item.games} title={item.day}/>)
}


const Statistics = ({title, playedGamesHistory, close, button}) => {
  const [mode, setMode] = useState('daily')
  return (
    <div style={{padding: 20}}>
      <h4>{title || 'Historique'}</h4>
      <div style={{...styles.center, padding: '10px 0'}}>
        <Button style={{minWidth: 180}} variant={mode === 'raw' ? 'contained' : undefined} color={mode === 'raw' ? 'primary': undefined} onClick={() => setMode('raw')}>
          Tous
        </Button>
        <Button variant={mode === 'daily' ? 'contained': undefined}  style={{marginLeft: '10px', minWidth: 180}} color={mode === 'daily' ? 'primary': undefined}
                      onClick={() => setMode('daily')}>
          Par jour
        </Button>
      </div>
      <div className="px-list" style={{padding: '10px'}}>
        {mode === 'daily' ? (
          <DailyGamesPlayedHistory playedGamesHistory={playedGamesHistory}/>
        ) : (
          <GamesPlayedHistory playedGamesHistory={playedGamesHistory}/>
        )}
      </div>
      <div style={{padding: '10px'}}>
        <TotalResult games={playedGamesHistory}/>
      </div>
      {button ? <CloseButton close={close}/> : null}
    </div>)
}


export default withPlayedGamesHistory(Statistics)