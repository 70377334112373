import { useEffect } from 'react'
import PropTypes from 'prop-types'
import useToggle from '../../hooks/useTogglable'

const Delay = ({ delay, children, placeholder = null }) => {
  const [visible, setVisible] = useToggle(false)
  useEffect(() => {
    //EFFECT SAFE
    const timeout = setTimeout(() => setVisible(true), delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay, setVisible])
  return visible ? children : placeholder
}

Delay.propTypes = {
  delay: PropTypes.number,
}
Delay.defaultProps = {
  delay: 500,
}

export default Delay
