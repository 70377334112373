import React, { useEffect, useRef, useState } from "react"
import { TimelineLite, TweenLite } from "gsap/all"
import { diceBounce, shadowBounce } from "./utils/lib/easings"
import useSound from "use-sound"
import diceThrownOnGameBoard from "../../assets/sounds/dice_throw_on_game_board.mp3"

const Dot = React.memo(({ diceSize }) => (
  <div
    style={{
      width: diceSize / 4.5,
      height: diceSize / 4.5,
    }}
    className="yms-dot"
  />
))

const Column = React.memo((props) => <div {...props} className="yms-column" />)

const diceFaceTransform = {
  1: (size) => `rotateX(90deg) translateZ(${size * 0.5}px)`,
  2: (size) => `rotateY(-90deg) translateZ(${size * 0.5}px)`,
  3: (size) => `rotateY(180deg) translateZ(${size * 0.5}px)`,
  4: (size) => `translateZ(${size * 0.5}px)`,
  5: (size) => `rotateY(90deg) translateZ(${size * 0.5}px)`,
  6: (size) => `rotateX(-90deg) translateZ(${size * 0.5}px)`,
}

const Face = React.memo(({ idx, className, diceSize }) => {
  const list = []
  if (idx === 4) {
    for (let col = 0; col < 2; col++) {
      const children = []
      for (let i = 0; i < 2; i++)
        children.push(<Dot diceSize={diceSize} key={i} />)
      list.push(<Column key={col}>{children}</Column>)
    }
  } else if (idx === 5) {
    for (let col = 0; col < 3; col++) {
      const children = []
      if (col === 1) {
        children.push(<Dot diceSize={diceSize} key={1} />)
      } else {
        for (let i = 0; i < 2; i++)
          children.push(<Dot diceSize={diceSize} key={i} />)
      }
      list.push(<Column key={col}>{children}</Column>)
    }
  } else if (idx === 6) {
    for (let col = 0; col < 2; col++) {
      const children = []
      for (let i = 0; i < 3; i++)
        children.push(<Dot diceSize={diceSize} key={i} />)
      list.push(<Column key={col}>{children}</Column>)
    }
  } else {
    for (let i = 0; i < idx; i++) {
      list.push(<Dot diceSize={diceSize} key={i} />)
    }
  }

  return (
    <div
      style={{
        opacity: 0,
        animationName: "fadeIn",
        animationFillMode: "both",
        animationDuration: "500ms",
        animationDelay: "200ms",
        height: diceSize,
        width: diceSize,
        transform: diceFaceTransform[idx](diceSize),
      }}
      className={className || "yms-side yms-side-" + idx}
    >
      {list}
    </div>
  )
})

const DiceFillerFace = React.memo(({ diceSize, namespace }) => {
  return (
    <div
      style={{
        opacity: 0,
        animationName: "fadeIn",
        animationFillMode: "both",
        animationDuration: "500ms",
        animationDelay: "200ms",
        height: diceSize,
        width: diceSize,
      }}
      className={`yms-side yms-side-${namespace}`}
    />
  )
})

const DiceStructure = ({ dice, onClick }) => {
  const faces = new Array(6).fill("")
  return (
    <div
      className="yms-dice-faces"
      style={{
        height: dice.size,
        width: dice.size,
      }}
    >
      {faces.map((face, i) => (
        <Face
          diceSize={dice.size}
          key={"f" + i}
          onClick={onClick}
          idx={i + 1}
        />
      ))}
      <DiceFillerFace diceSize={dice.size} namespace="x" />
      <DiceFillerFace diceSize={dice.size} namespace="y" />
      <DiceFillerFace diceSize={dice.size} namespace="z" />
    </div>
  )
}

const faceRotationValue = {
  1: {
    rotateX: -90,
    rotateY: 45,
    rotateZ: 0,
  },
  2: {
    rotateX: -90,
    rotateY: 45,
    rotateZ: 90,
  },
  3: {
    rotateX: 0,
    rotateY: 180,
    rotateZ: 45,
  },
  4: {
    rotateX: 0,
    rotateY: 0,
    rotateZ: 45,
  },
  5: {
    rotateX: -90,
    rotateY: -45,
    rotateZ: -90,
  },
  6: {
    rotateX: 90,
    rotateY: 45,
    rotateZ: 0,
  },
}

const getFaceRotation = (score) => faceRotationValue[score]

const getRandomRotation = () => ({
  rotateX: -720 + Math.random() * 1440,
  rotateY: -720 + Math.random() * 1440,
  rotateZ: -720 + Math.random() * 1440,
})

const createDiceLockTween = ({ diceRef, shadowRef, dice, duration, size }) => {
  const diceTween = new TimelineLite()
  const transform = getFaceRotation(dice.value)
  const to = {
    x: dice.end.x,
    y: 0, //dice.end.y,
    z: dice.end.z,
    duration: duration / 1000,
    ease: diceBounce,
    transform: `rotateX(${transform.rotateX}deg) rotateY(${transform.rotateY}deg) rotateZ(${transform.rotateZ}deg)`,
  }
  diceTween.to(diceRef.current, to).play()
  const shadowTween = new TimelineLite() //{paused: true}
  shadowTween
    .to(shadowRef.current, {
      x: to.x,
      y: to.y,
      z: to.z - size / 2,
      duration: duration / 1000,
      ease: shadowBounce,
    })
    .play()
}

const createDiceTween = ({
  dice,
  diceRef,
  shadowRef,
  delay = 0,
  duration,
  size,
  initial,
}) => {
  const diceTween = new TimelineLite()
  const transform = getFaceRotation(dice.value)
  if (initial) {
    const from = {
      x: dice.start.x,
      y: dice.start.y,
      z: dice.start.z,
      ...getRandomRotation(),
    }
    TweenLite.set(diceRef.current, from)
  }

  const to = {
    x: dice.end.x,
    y: dice.end.y,
    z: dice.end.z,
    transform: `rotateX(${transform.rotateX}deg) rotateY(${transform.rotateY}deg) rotateZ(${transform.rotateZ}deg)`,
    delay: delay / 1000,
    duration: duration / 1000,
    ease: diceBounce,
  }
  diceTween.to(diceRef.current, to)

  const shadowTween = new TimelineLite() //{paused: true}
  if (initial) {
    TweenLite.set(shadowRef.current, { x: to.x - 50, y: to.y + 100, z: to.z })
  }

  shadowTween.to(shadowRef.current, {
    x: to.x,
    y: to.y,
    z: to.z - size / 2,
    delay: delay / 1000,
    duration: duration / 1000,
    ease: shadowBounce,
  })

  return Promise.all([diceTween.play(), shadowTween.play()])
}

export const Dice = React.memo(
  ({
    score,
    dice,
    fallingTime,
    soundVolume,
    delay = 0,
    diceLockAnimationTime,
  }) => {
    const [playSound] = useSound(diceThrownOnGameBoard, {
      //interrupt: true,
      volume: soundVolume / 100,
    })
    const diceRef = useRef()
    const shadowRef = useRef()
    //const [lock, toggleLock] = useToggle()

    const [initialRender, setInitialRender] = useState(true)

    // const onLockClickHandler = () => {
    //   console.log(lock)
    // }

    useEffect(() => {
      const timeout = setTimeout(() => {
        playSound()
      }, delay + fallingTime)
      return () => {
        clearTimeout(timeout)
      }
    }, [playSound, delay, fallingTime, soundVolume])

    useEffect(() => {
      console.log("effect", initialRender)

      if (!dice.kept) {
        createDiceTween({
          dice,
          diceRef,
          shadowRef,
          delay,
          duration: initialRender ? fallingTime : diceLockAnimationTime,
          initial: initialRender,
        }).then(() => {
          if (initialRender) {
            setInitialRender(false)
          }
        })
      }
      if (dice.kept) {
        createDiceLockTween({
          diceRef,
          shadowRef,
          dice,
          duration: diceLockAnimationTime,
        })
      }
    }, [diceLockAnimationTime, dice, delay, fallingTime, initialRender])

    // const onCursorOver = () => {
    //   const diceTween = new TimelineLite(); //{paused: true}
    //   diceTween
    //     .to(diceRef.current, {
    //       z: dice.end.z + 30,
    //     })
    //     .play();
    // };
    // const onCursorOut = () => {
    //   const diceTween = new TimelineLite(); //{paused: true}
    //   diceTween
    //     .to(diceRef.current, {
    //       z: dice.end.z,
    //     })
    //     .play();
    // };
    const diceSizeStyle = {
      width: dice.size,
      height: dice.size,
    }

    return (
      <div
        className={`yms-dice-container ${dice.kept ? "picked" : ""}`}
        onClick={dice.toggle}
        // onMouseEnter={onCursorOver}
        // onMouseLeave={onCursorOut}
        style={{ transformStyle: "preserve-3d", transform: "translate(0,0)" }}
      >
        <div
          ref={shadowRef}
          className="yms-dice-shadow"
          style={diceSizeStyle}
        />
        <div
          ref={diceRef}
          className={"yms-dice face-" + score}
          style={diceSizeStyle}
        >
          <DiceStructure dice={dice} />
        </div>
      </div>
    )
  }
)

export default Dice
