import React from 'react'
import NumLevelComponent from './types/NumLevelComponent'
import OfAKindLevelComponent from './types/OfAKindLevelComponent'
import FlushLevelComponent from './types/FlushLevelComponent'
import TotalLevelComponent from './types/TotalLevelComponent'
import CustomLevelComponent from './types/CustomLevelComponent'

const LevelComponent = ({level}) => {

  switch (level.type) {
    case 'num': {
      return <NumLevelComponent level={level} className="score-row" />
    }
    case 'ofakind': {
      //return <div>F</div>
      return <OfAKindLevelComponent level={level} className="score-row" />
    }
    case 'flush': {
      return <FlushLevelComponent level={level} className="score-row" />
    }
    case 'total': {
      return <TotalLevelComponent level={level} className="total-row" />
    }
    case 'custom': {
      return <CustomLevelComponent level={level} className="score-row" />
    }
    default: {
      return null
    }
  }
}


export default LevelComponent
