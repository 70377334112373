import React from "react";
import { Router, Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import history from "./history";
import ApplicationLayout from "../layout/application/ApplicationLayout";
import Home from "../pages/Home";
import Game from '../pages/Game'
import Dices from '../pages/Dices'

const Application = (props) => (
  <ApplicationLayout {...props}>
    <Route exact path="/" component={Home} />
    <Route exact path="/dices" component={Dices} />
    <Route exact path="/game" component={Game} />
  </ApplicationLayout>
);

const router = (
  <ConnectedRouter history={history}>
    <Router history={history}>
      <Route path="/" component={Application} />
    </Router>
  </ConnectedRouter>
);

export default router;
