import React, {useContext} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Statistics from './Statistics'
import StatsModalContext from '../../leader-board/contexts/StatsModalContext'

const StatsDialog = () => {
  const context = useContext(StatsModalContext)
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={context.open}
      onClose={() => context.toggle(false)}
    >
      <Statistics />
    </Dialog>
  )
}


export default StatsDialog
