import React, { Component, useContext } from "react";
import { DiceFlatComponent } from "../../../dice/FlatDice";
import Button from "@material-ui/core/Button";
import withPlayers from "../../../players/HOC/withPlayers";
import {
  clearMovePlayerAction,
  setMovePlayerAction,
} from "../../../players/actions/playersActions";
import { ScoreContainer } from "../../containers/LevelRow";
import ScoreDialog from "../../../../layout/dialog/ScoreDialog";
import { ScoreModalContext } from "../../contexts/ScoreModalContext";
import { getNextPlayer } from "../../../players/utils";
import { SoundContext } from "../../../../layout/sound/contexts/SoundContext";

export class BlankKey extends Component {
  render() {
    return (
      <div className="col-md-4 col-sm-4 col-xs-4 text-center padkey danger-key">
        &nbsp;
      </div>
    );
  }
}

export class DiceKey extends React.Component {
  triggerScore = () => {
    if (this.props.onChange) this.props.onChange(this.props.value);
  };

  render() {
    const { value } = this.props;
    return (
      <div
        className="col-md-2 col-sm-2 col-xs-2 text-center"
        onClick={this.triggerScore}
      >
        <DiceFlatComponent className="animated bounceIn" faceUp={value} />
      </div>
    );
  }
}

export class PlaceholderKey extends React.Component {
  render() {
    return (
      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
        <div className="dice-placehoder" />
      </div>
    );
  }
}

//
// export class DicePad extends React.Component {
//   triggerScore = (v,i) => {
//     if(this.props.onChange)  this.props.onChange(v,i)
//   }
//   picked = () => {
//     const { picked } = this.props
//     const keys = picked.map((v,i)=><DiceKey onChange={v =>this.triggerScore(v,i)} key={'e'+i}  value={v}  />)
//     keys.unshift(<div className="col-md-1 col-sm-1 col-xs-1 text-center" />)
//     if (picked.length < 5) {
//       for (let i = 1; i < 6 - picked.length ; i++) {
//         keys.push(<PlaceholderKey key={'b'+i} />)
//       }
//     }
//     return keys
//   }
//   dices = () => {
//     const keys = []
//     for (let i = 1; i < 7 ; i++) {
//       keys.push(<DiceKey onChange={this.triggerScore} key={'e'+i}  value={i}  />)
//     }
//     return keys
//   }
//   render () {
//     const { picked, style } = this.props
//     const children = picked ? this.picked() : this.dices()
//     return (<div className="row" style={style} children={children} />)
//   }
// }

// class ButtonZero extends Component {
//   triggerScore = () => {
//     if(this.props.onChange) this.props.onChange(0)
//   }
//   render () {
//     const { label, secondary } = this.props
//     return (<Button color={secondary ? 'primary' : 'secondary'} onClick={this.triggerScore}>
//       {label}
//     </Button>)
//   }
// }
//
// class ButtonCancel extends Component {
//   triggerScore = () => {
//     if(this.props.onChange) this.props.onChange( 'x' )
//   }
//   render () {
//     const { label, secondary} = this.props //value
//     return (<Button secondary={secondary ? 'primary' : 'secondary'}  onClick={this.triggerScore}>
//       {label}
//     </Button>)
//   }
// }

const styles = {
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};

const NotYourTurnInfo = ({ legitPlayerName, playerName }) => {
  return (
    <div className="not-your-turn-to-play animated fadeIn">
      Ce n'est pas le tour {playerName}
    </div>
  );
};

export const ScoreWrapper = withPlayers(
  ({ children, player, level, dispatch, players }) => {
    const context = useContext(ScoreModalContext);
    const sound = useContext(SoundContext);
    const clear = () => {
      dispatch(clearMovePlayerAction({ player, level }));
      sound.erased();
      context.clearTurn();
    };
    const setScore = () => {
      dispatch(setMovePlayerAction({ player, level, score: 0 }));
      sound.played();
      context.clearTurn();
    };
    return (
      <React.Fragment>
        <div style={{ padding: "10px 10px 30px 10px" }}>
          <div style={{ ...styles.center, marginBottom: "40px" }}>
            {children}
          </div>
          <div className="text-center">
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              onClick={setScore}
            >
              Rayer
            </Button>
            <Button variant="contained" color="secondary" onClick={clear}>
              Effacer
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

const getKeyDisplay = (score, level) => {
  const { key } = level.config;
  const r = key * (score / key) - key * 3;
  if (r === 0) {
    return "=";
  } else if (r < 0) {
    return r;
  }
  return "+" + r;
};

export const ScoreComponent = withPlayers(
  ({ players, player, level, children }) => {
    const currentPlayer = getNextPlayer(players);
    const highlight = getNextPlayer(players).id === player.id;
    const context = useContext(ScoreModalContext);
    const open =
      context.turn.playerId === player.id && context.turn.levelId === level.id;
    const _score = player.scoreBoard.find((o) => o.id === level.id);
    const score = _score ? _score.value : 0;
    let v = score;
    if (level.type === "num") {
      v = getKeyDisplay(score, level);
      if (level.config.key === 1) v = score > 0 ? "+" + score : "/";
    }
    if (level.type === "custom") {
      v = typeof v === "number" && v === 0 ? "/" : v; //getKeyDisplay(score, level)
      //if (level.config.key === 1) v = score > 0 ? '+' + score : '/'
    }
    const prefix = typeof level.config.name === "number" ? "les " : "";
    const value = _score ? v : "";

    return (
      <ScoreContainer
        className={`${highlight ? "highlight" : ""} ${value ? "played" : ""}`}
        level={level}
      >
        <ScoreDialog
          title={prefix + level.config.name + " pour " + player.name}
          open={open}
          onClose={context.clearTurn}
          infoMessage={
            currentPlayer.name !== player.name && (
              <NotYourTurnInfo playerName={player.name} />
            )
          }
        >
          <ScoreWrapper player={player} level={level}>
            {children}
          </ScoreWrapper>
        </ScoreDialog>
        <span
          onClick={() =>
            context.setTurn({ playerId: player.id, levelId: level.id })
          }
        >
          {value}
        </span>
      </ScoreContainer>
    );
  }
);

export default ScoreWrapper;
