import React from 'react'
import LevelComponent from '../levels/components/LevelComponent'
import BoardHeader from '../levels/components/types/BoardHeader'

const HorizontalLayout = ( {levels = [], settings = {}}) => {
  const collection = levels.map(level => (
    <LevelComponent settings={settings} key={level.id} level={level}/>
    ))

  const border = 9
  return (
    <div className="row no-gutters animated fadeIn">
      <div className="col-md-6 col-sm-6 col-xs-6">
        <div className={'board  ' +settings.theme }>
          <BoardHeader />
          {collection.slice(0, border)}
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-6">
        <div className={'board  ' +settings.theme }>
          <BoardHeader />
          {collection.slice(border, levels.length)}
        </div>
      </div>
    </div>
  )
}

export default HorizontalLayout
