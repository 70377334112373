import { useRef, useEffect } from 'react'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    //EFFECT SAFE
    ref.current = value
  })
  return ref.current
}

export default usePrevious
