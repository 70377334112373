import React, {useContext} from 'react'
import {LevelRow} from '../../containers/LevelRow'
import {ScoreComponent} from './SharedLevelComponent'
import withPlayers from '../../../players/HOC/withPlayers'
import store from '../../../../config/store'
import {setMovePlayerAction} from '../../../players/actions/playersActions'
import {ScoreModalContext} from '../../contexts/ScoreModalContext'
import {SoundContext} from '../../../../layout/sound/contexts/SoundContext'


const ScoreKey = ({level, player}) => {
  const context = useContext(ScoreModalContext)
  const sound = useContext(SoundContext)
  const {value} = level.config

  const onClickHandler = () => {
    store.dispatch(setMovePlayerAction({player, level, score: value}))
    sound.played()
    context.clearTurn()
  }

  return (
    <div className="padkey single" onClick={onClickHandler}>
      {value}
    </div>
  )
}


const ScorePad = (props) => {
  return (
    <div className="pad">
      <ScoreKey {...props} size={4}/>
    </div>
  )
}


const FlushLevelComponent = ({level, players, className}) => {

  const scores = players.map(player => {

    return <ScoreComponent key={player.id} {...{player, level}}>
      <ScorePad {...{player, level}}/>
    </ScoreComponent>
  })
  return (<LevelRow className={className} level={level}>{scores}</LevelRow>)
}

export default withPlayers(FlushLevelComponent)


//export default withSettings(withPlayers(FlushLevelComponent))