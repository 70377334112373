import EncodedStorage from '../../../utils/EncodedStorage'
import {getInitialState} from '../../reducers-tools/reducers-tools'


const STATE_STORAGE_KEY = '@yamsy/PLAYED_GAMES_HISTORY'
const storage = new EncodedStorage({key: STATE_STORAGE_KEY})
const defaultState = getInitialState(storage, {collection: [].concat()})
const saveState = (state) => {
  storage.set(JSON.stringify(state))
  return state
}
//
// saveState({
//   ...defaultState,
//   collection: defaultState.collection.filter(g => !g.id)
// })


export const PLAYED_GAMES_HISTORY_ACTION_MAPPER = {
  SAVE_GAME: 'PLAYED_GAMES_HISTORY_SAVE_GAME',
}

const defaultGame = ({id = ''}) => {
  return {
    id,
    date: new Date().getTime(),
    players: [].concat(),
    winner: JSON.parse('{}')
  }
}

const saveGame = (state, {game, totals}) => {
  const sorted = totals.sort((a,b) => b.total - a.total)
  const prev = state.collection.find(g => g.id === game.id) || defaultGame({id: game.id})
  return {
    ...state,
    collection: state.collection.filter(g => g.id !== game.id).concat({
      ...prev,
      players: sorted,
      winner: sorted[0]
    })
  }
}


function playedGamesHistory(state = defaultState, action) {
  switch (action.type) {
    case PLAYED_GAMES_HISTORY_ACTION_MAPPER.SAVE_GAME: {
      return saveState(saveGame(state, action.payload))
    }
    default: {
      return state
    }
  }

}


export default playedGamesHistory
