import React from 'react'
import {Icon} from '@material-ui/core'

export const fontStyle = {
  fontSize: '14px',
  color: '#BBB'
}
const badgeSize = 40
const badgeStyle = {
  display: 'inline-block',
  border: '3px solid #CCC',
  width: badgeSize,
  height: badgeSize,
  lineHeight: '32px',
  borderRadius: badgeSize,
  textAlign: 'center',
  verticalAlign: 'top',
  marginRight: '2px',
  backgroundColor: '#F4F4F4',
  textShadow: '0 0 0'
}

export function Badge(props) {
  return (<span title={props.title} style={badgeStyle} className="ym-tooltip">
        <span className="ym-tooltip-label animated fadeInUp">{props.title}</span>
    {props.children}
    </span>)
}

export function BadgeList(player) {
  const badges = []
  const yams = player.scoreBoard.find(o => o.id === 'ofakind-5' && o.value > 0)
  if (yams) {
    //Yams !
    badges.push(
      <Badge title="Yams!" key={'yams'}>
        <Icon style={fontStyle} className="fa fa-rocket"/>
      </Badge>
    )
  }

  const flushS = player.scoreBoard.find(o => o.id === 'flush-s' && o.value > 0)
  const flushL = player.scoreBoard.find(o => o.id === 'flush-l' && o.value > 0)
  if (flushS && flushL) {
    //Flushes !
    badges.push(
      <Badge title="Les deux suites !" key={'flush'}>
        <Icon style={fontStyle} className="fa fa-bolt"/>
      </Badge>
    )
  }

  const numberScore = player.scoreBoard.reduce((prev, v) => {
    return prev + (/^nums/.test(v.id) ? v.value : 0)
  }, 0)

  if (numberScore < 60) {
    // BOnus Top !
    badges.push(
      <Badge title="Pas sorti en haut ! " key={'notout'}>
        <Icon style={fontStyle} className="fa fa-thumbs-o-down"/>
      </Badge>
    )
  }


  if (player.total > 399) {
    // BOnus Top !
    badges.push(
      <Badge title="400 et plus !" key={'plus400'}>
        <Icon style={fontStyle} className="fa fa-line-chart"/>
      </Badge>)
  }

  if (player.total > 419) {
    // BOnus Top !
    badges.push(
      <Badge title="420 et plus ! On fire !" key={'plus420'}>
        <Icon style={fontStyle} className="fa fa-fire"/>
      </Badge>
    )
  }


  return badges
}

export default BadgeList